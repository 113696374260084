import AdminComponent from '@/components/dashboard/AdminComponent';

import AcquisitionSearch from '@/views/search/AcquisitionSearch';
import AcquisitionCreate from '@/views/create/AcquisitionCreate';
import AcquisitionUpdate from '@/views/update/AcquisitionUpdate';

export default {
  path: '/acquisition',
  meta: { auth: true },
  component: AdminComponent,
  children: [
    {
      path: 'search',
      name: 'acquisition-search',
      component: AcquisitionSearch
    },
    {
      path: 'create',
      name: 'acquisition-create',
      component: AcquisitionCreate
    },
    {
      path: 'edit/:id',
      name: 'acquisition-edit',
      component: AcquisitionUpdate,
      props: true
    }
  ]
};
