<template>
  <div class="menu-container" :class="{ openMenuContainer: openMenu }">
    <div class="logo-container">
      <router-link to="/" @click="activeLinkRouteRemove">
        <img src="@/assets/logo.png" alt="" />
      </router-link>
    </div>

    <div class="user-container" @click="toggleDropdown('navUsuario')">
      <div class="avatar">
        <img v-if="me.photo" :src="me.photo" alt="" />
        <img v-else src="@/assets/avatar.png" alt="" />
        <p>
          {{ me.name }}
          <i
            :class="
              dropMenu.navUsuario
                ? 'chevron down icon icon-menu'
                : 'chevron right icon icon-menu'
            "
          ></i>
        </p>
      </div>

      <transition name="slide">
        <ul class="links submenu userMenu p-1" v-if="dropMenu.navUsuario">
          <li>
            <router-link :to="{ name: 'user-edit-photo' }">
              <i class="image icon"></i> Alterar Foto
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'user-edit-password' }">
              <i class="unlock icon"></i>Alterar Senha
            </router-link>
          </li>
          <li>
            <a href="#" @click.prevent="logout"
              ><i class="sign-out icon"></i> Sair</a
            >
          </li>
        </ul>
      </transition>
    </div>

    <div class="link-container" v-if="showItems.pageAdmin">
      <ul>
        <li @click="toggleDropdown('navCadastro')">
          <a href="#">
            <i :class="'icon ' + linksMenu.menuAdmin.icon"></i>
            {{ linksMenu.menuAdmin.name }}
            <i
              :class="
                dropMenu.navCadastro
                  ? 'chevron down icon icon-menu'
                  : 'chevron right icon icon-menu'
              "
            ></i
          ></a>
        </li>
        <transition name="dropMenu">
          <div class="submenu" v-if="dropMenu.navCadastro">
            <div
              v-for="(link, index) in linksMenu.menuAdmin.subLinks"
              :key="index"
            >
              <router-link
                v-if="checkPermissionMenu(link.pagePermissions)"
                :to="link.route"
                :class="[{ active: activeLinkRoute(link.route) }, 'link-route']"
              >
                {{ link.name }}
              </router-link>
            </div>
          </div>
        </transition>
      </ul>
    </div>

    <div class="link-container" v-if="showItems.pageStock">
      <ul>
        <li @click="toggleDropdown('navEstoque')">
          <a href="#">
            <i :class="'icon ' + linksMenu.menuStock.icon"></i>
            {{ linksMenu.menuStock.name }}
            <i
              :class="
                dropMenu.navEstoque
                  ? 'chevron down icon icon-menu'
                  : 'chevron right icon icon-menu'
              "
            ></i
          ></a>
        </li>
        <transition name="dropMenu">
          <div class="submenu" v-if="dropMenu.navEstoque">
            <div
              v-for="(link, index) in linksMenu.menuStock.subLinks"
              :key="index"
            >
              <router-link
                v-if="checkPermissionMenu(link.pagePermissions)"
                :to="link.route"
                :class="[{ active: activeLinkRoute(link.route) }, 'link-route']"
              >
                {{ link.name }}
              </router-link>
            </div>
          </div>
        </transition>
      </ul>
    </div>

    <div class="link-container" v-if="showItems.pageFinanceiro">
      <ul>
        <li @click="toggleDropdown('navFinanceiro')">
          <a href="#">
            <i :class="'icon ' + linksMenu.menuFinanceiro.icon"></i>
            {{ linksMenu.menuFinanceiro.name }}
            <i
              :class="
                dropMenu.navFinanceiro
                  ? 'chevron down icon icon-menu'
                  : 'chevron right icon icon-menu'
              "
            ></i
          ></a>
        </li>
        <transition name="dropMenu">
          <div class="submenu" v-if="dropMenu.navFinanceiro">
            <div
              v-for="(link, index) in linksMenu.menuFinanceiro.subLinks"
              :key="index"
            >
              <router-link
                v-if="checkPermissionMenu(link.pagePermissions)"
                :to="link.route"
                :class="[{ active: activeLinkRoute(link.route) }, 'link-route']"
              >
                {{ link.name }}
              </router-link>
            </div>
          </div>
        </transition>
      </ul>
    </div>

    <div class="link-container" v-if="showItems.pageConsignado">
      <ul>
        <li @click="toggleDropdown('navConsignado')">
          <a href="#">
            <i :class="'icon ' + linksMenu.menuConsignado.icon"></i>
            {{ linksMenu.menuConsignado.name }}
            <i
              :class="
                dropMenu.navConsignado
                  ? 'chevron down icon icon-menu'
                  : 'chevron right icon icon-menu'
              "
            ></i
          ></a>
        </li>
        <transition name="dropMenu">
          <div class="submenu" v-if="dropMenu.navConsignado">
            <div
              v-for="(link, index) in linksMenu.menuConsignado.subLinks"
              :key="index"
            >
              <router-link
                v-if="checkPermissionMenu(link.pagePermissions)"
                :to="link.route"
                :class="[{ active: activeLinkRoute(link.route) }, 'link-route']"
              >
                {{ link.name }}
              </router-link>
            </div>
          </div>
        </transition>
      </ul>
    </div>

    <div class="link-container" v-if="showItems.pageEcommerce">
      <ul>
        <li @click="toggleDropdown('navEcommerce')">
          <a href="#">
            <i :class="'icon ' + linksMenu.menuEcommerce.icon"></i>
            {{ linksMenu.menuEcommerce.name }}
            <i
              :class="
                dropMenu.navEcommerce
                  ? 'chevron down icon icon-menu'
                  : 'chevron right icon icon-menu'
              "
            ></i
          ></a>
        </li>
        <transition name="dropMenu">
          <div class="submenu" v-if="dropMenu.navEcommerce">
            <div
              v-for="(link, index) in linksMenu.menuEcommerce.subLinks"
              :key="index"
            >
              <router-link
                v-if="checkPermissionMenu(link.pagePermissions)"
                :to="link.route"
                :class="[{ active: activeLinkRoute(link.route) }, 'link-route']"
              >
                {{ link.name }}
              </router-link>
            </div>
          </div>
        </transition>
      </ul>
    </div>

    <div class="link-container" v-if="showItems.pagePDV">
      <ul>
        <li @click="toggleDropdown('navPDV')">
          <a href="#">
            <i :class="'icon ' + linksMenu.menuPDV.icon"></i>
            {{ linksMenu.menuPDV.name }}
            <i
              :class="
                dropMenu.navPDV
                  ? 'chevron down icon icon-menu'
                  : 'chevron right icon icon-menu'
              "
            ></i
          ></a>
        </li>
        <transition name="dropMenu">
          <div class="submenu" v-if="dropMenu.navPDV">
            <div
              v-for="(link, index) in linksMenu.menuPDV.subLinks"
              :key="index"
            >
              <router-link
                v-if="checkPermissionMenu(link.pagePermissions)"
                :to="link.route"
                :class="[{ active: activeLinkRoute(link.route) }, 'link-route']"
              >
                {{ link.name }}
              </router-link>
            </div>
          </div>
        </transition>
      </ul>
    </div>

    <div class="link-container" v-if="showItems.pageRelatorio">
      <ul>
        <li @click="toggleDropdown('navRelatorio')">
          <a href="#">
            <i :class="'icon ' + linksMenu.menuRelatorio.icon"></i>
            {{ linksMenu.menuRelatorio.name }}
            <i
              :class="
                dropMenu.navRelatorio
                  ? 'chevron down icon icon-menu'
                  : 'chevron right icon icon-menu'
              "
            ></i
          ></a>
        </li>
        <transition name="dropMenu">
          <div class="submenu" v-if="dropMenu.navRelatorio">
            <div
              v-for="(link, index) in linksMenu.menuRelatorio.subLinks"
              :key="index"
            >
              <router-link
                v-if="checkPermissionMenu(link.pagePermissions)"
                :to="link.route"
                :class="[{ active: activeLinkRoute(link.route) }, 'link-route']"
              >
                {{ link.name }}
              </router-link>
            </div>
          </div>
        </transition>
      </ul>
    </div>
  </div>
</template>

<script>
import linksMenu from '@/configs/links-menu';
import swal from 'sweetalert';
import { useToast } from 'vue-toastification';

const toast = useToast();
export default {
  name: 'NavMenu',

  props: {
    openMenu: {
      required: true,
      type: Boolean
    }
  },

  created() {
    this.checkACL();
  },

  data() {
    return {
      linksMenu,
      dropMenu: {
        navUsuario: false,
        navCadastro: false,
        navEstoque: false,
        navEcommerce: false,
        navFinanceiro: false,
        navPDV: false,
        navRelatorio: false,
        navConsignado: false
      },

      activeLink: false,
      permissionsPage: [],

      showItems: {
        pageAdmin: false,
        pagePDV: false,
        pageFinanceiro: false,
        pageStock: false,
        pageRelatorio: false,
        pageEcommerce: false,
        pageConsignado: false
      }
    };
  },

  methods: {
    activeLinkRoute(nameLink) {
      let x1 = nameLink.split('/');
      return this.$route.matched[0].path === '/' + x1[1];
    },

    activeLinkRouteRemove() {
      this.activeLink = false;
    },

    toggleDropdown(dropdownName) {
      // Fecha todos os dropdowns
      Object.keys(this.dropMenu).forEach(key => {
        if (key !== dropdownName) {
          this.dropMenu[key] = false;
        }
      });
      // Alterna o dropdown que foi clicado
      this.dropMenu[dropdownName] = !this.dropMenu[dropdownName];
    },

    logout() {
      swal({
        title: 'Atenção!',
        text: `Deseja realmente sair do sistema ${this.me.name} ?`,
        closeOnClickOutside: false,
        buttons: {
          cancel: {
            text: 'Não',
            visible: true,
            closeModal: true
          },
          confirm: 'Sim'
        }
      }).then(result => {
        if (result) {
          this.$store.dispatch('logout');
          toast.success('Logout efetuado com sucesso!', { timeout: 2000 });
          setTimeout(() => this.$router.push({ name: 'login' }), 2000);
        }
      });
    },

    async checkACL() {
      this.$store
        .dispatch('ACLItemsNav')
        .then(r => {
          this.permissionsPage = r;
          this.permissionsPage.forEach(item => {
            if (linksMenu.menuAdmin.pageNavCheck.includes(item)) {
              this.showItems.pageAdmin = true;
            }

            if (linksMenu.menuStock.pageNavCheck.includes(item)) {
              this.showItems.pageStock = true;
            }

            if (linksMenu.menuAdmin.pageNavCheck.includes(item)) {
              this.showItems.pageFinanceiro = true;
            }

            if (linksMenu.menuEcommerce.pageNavCheck.includes(item)) {
              this.showItems.pageEcommerce = true;
            }

            if (linksMenu.menuPDV.pageNavCheck.includes(item)) {
              this.showItems.pagePDV = true;
            }

            if (linksMenu.menuRelatorio.pageNavCheck.includes(item)) {
              this.showItems.pageRelatorio = true;
            }

            if (linksMenu.menuConsignado.pageNavCheck.includes(item)) {
              this.showItems.pageConsignado = true;
            }
          });
        })
        .catch(() => {
          this.$router.push({ name: 'home' });
        });
    },

    checkPermissionMenu(page) {
      let shouldSkip = false;
      this.permissionsPage.forEach(value => {
        if (shouldSkip) return;

        if (page.includes(value)) {
          shouldSkip = true;
        }
      });
      return shouldSkip;
    }
  },

  computed: {
    me() {
      return this.$store.state.auth.me;
    }
  }
};
</script>

<style scoped>
.userMenu {
  margin-top: 15px;
}

.menu-container {
  left: 0;
  z-index: 2;
  width: 270px;
  position: fixed;
  padding: 0 10px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100vh;
  min-height: 100%;
  box-shadow: 0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0 rgb(0 0 0 / 12%),
    0 8px 10px -5px rgb(0 0 0 / 20%);
}

.menu-container .logo-container {
  padding: 10px 0;
  border-bottom: 1px inset #c3c3c3;
}

.menu-container .logo-container img {
  display: block;
  margin: 0 auto;
  max-width: 180px;
}

.menu-container .user-container {
  border-bottom: 1px solid #c3c3c3;
  padding-top: 5px;
  padding-bottom: 5px;
  min-height: 48px;
}

.menu-container .user-container:hover {
  cursor: pointer;
}

.menu-container .user-container .avatar img {
  float: left;
  max-width: 35px;
  margin-right: 10px;
  border-radius: 50%;
}

.menu-container .user-container p {
  margin: 0;
  color: #000;
  padding-top: 5px;
}

.menu-container .submenu {
  width: 100%;
  clear: both;
  overflow: hidden;
  list-style-type: none;
  transform-origin: top;
  background-color: #e3e3e3;
  transition: transform 0.4s ease-in-out;
}

.menu-container .submenu li {
  padding: 5px 0;
}

.menu-container .submenu li a {
  font-size: 16px;
}

.menu-container .links a {
  color: #000;
  height: 35px;
  display: block;
  line-height: 1em;
  text-decoration: none;
}

.menu-container .links a i {
  color: #686666;
  font-size: 16px;
  margin-right: 2px;
}

.menu-container .icon-menu,
.link-container .icon-menu {
  right: 15px;
  position: absolute;
}

.link-route {
  height: 45px;
  padding: 10px 0 10px 10px;
  display: block;
  color: #000000;
  text-decoration: none;
  background-color: #ebebeb;
}

.link-route:hover {
  color: #ffffff;
  background-color: #000000;
  -webkit-transition: background-color 500ms linear;
  -ms-transition: background-color 500ms linear;
  transition: background-color 500ms linear;
}

.link-container ul {
  padding: 0;
  list-style: none;
  margin: 10px 0 0 0;
}

.link-container ul:hover {
  cursor: pointer;
}

.link-container ul li {
  height: 45px;
  padding: 10px;
  background-color: #ebebeb;
}

.link-container ul li a {
  color: #000000;
  font-size: 16px;
  text-decoration: none;
}

.link-container ul li img {
  height: 1em;
  text-align: center;
  margin: 0 0.25rem 0.2rem 0;
}

.link-container .submenu {
  width: 100%;
  overflow: hidden;
  list-style-type: none;
  transform-origin: top;
  transition: transform 0.4s ease-in-out;
}

.active {
  color: white !important;
  background-color: #000000;
}

.dropMenu-enter,
.dropMenu-leave-to,
.dropMenu-enter-from {
  transform: scaleY(0);
}

.slide-enter,
.slide-leave-to,
.slide-enter-from {
  transform: scaleY(0);
}

@media (max-width: 991px) {
  .menu-container {
    left: -260px;
    transition: all 0.6s ease-in-out;
  }

  .openMenuContainer {
    left: 0;
    transition: all 0.6s ease-in-out;
  }
}
</style>
