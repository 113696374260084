<template>
  <div class="painel p-4 pt-5">

    <div role="button" @click="this.$emit('close')" class="btn-close" v-if="btnClose"></div>

    <br>
      <div v-if="textOne">
        <h1 class="preloader-send">Emitindo NF</h1>
      </div>

      <div v-if="textTwo">
        <h1 class="preloader-send">Enviando para Sefaz</h1>
      </div>

      <div v-if="textThree">
        <h1>{{textEnd}}</h1>
      </div>

    <br>
  </div>

</template>

<script>

export default {
  name: "NFModalGenerate",
  components: {
  },

  props: {
    id: {
      required: true
    },

    textEnd:{
      required: true
    }


  },

  data() {
    return {
      textOne: true,
      textTwo: false,
      textThree: false,
      btnClose: false,
      formData: {
        id: this.id
      }
    }
  },

  mounted() {
    this.startCounting();
  },

  methods: {
    submit() {
      this.textOne = false
      this.textTwo = true
      this.$store.dispatch('storeNF', this.formData)
          .then(() => {
            setTimeout(() => {
              this.textTwo = false
              this.endClose()
            }, 0);


          }).catch(() => {
        this.$emit('close')
      })
    },

    startCounting(){
      setTimeout(() => {
        this.textOne = false
        this.textTwo = true

        setTimeout(() => {
          this.textTwo = false
          this.textThree = true
          this.btnClose = true

          setTimeout(() => {
            this.endClose();
          }, 10000)
        }, 20000)
      }, 20000)
    },

    endClose() {
      this.textThree = true
      setTimeout(() => {
        this.$emit('close')
      }, 0);
    },
  },
}
</script>

<style scoped>
.painel {
  position: relative;
  background: #FFFFFF;
}

.btn-close {
  top: 10px;
  right: 10px;
  position: absolute;
}

h1 {
  font-size: 20px;

  padding: 10px 50px;
  text-transform: uppercase;
}
</style>