<template>
  <painel-component :items="itemsOne">
    <section id="tab-item-1">
      <span class="badge rounded-pill text-bg-dark">Código: {{ formData.code }}</span>

      <br /><br />
      <row-component>
        <col-component
          :class-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
          :class="{ formError: errors.desc }"
        >
          <label>Descrição:</label>
          <input-component :type-input="'text'" v-model="formData.desc" />
        </col-component>

        <col-component
          :class-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
          :class="{ formError: errors.code }"
        >
          <label>Valor:</label>
          <input-component v-model="formData.price" v-money="MONEY" />
        </col-component>

        <col-component
          :class-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
          :class="{ formError: errors.status }"
        >
          <label>Status:</label>
          <MultiSelect
            v-model="formData.status"
            :options="selectActiveInative"
            :searchable="true"
            :noOptionsText="noOptionsText"
            :noResultsText="noResultsText"
          />
        </col-component>
      </row-component>

      <row-component>
        <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <button-submit
            class="w-100"
            :disabled="sendForm"
            @click="submit"
            icon="save"
            name="Salvar"
          />
        </col-component>

        <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <button-route class="w-100" :items="itemsTwo" />
        </col-component>
      </row-component>
    </section>
  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent.vue';
import RowComponent from '@/components/row/RowComponent.vue';
import ColComponent from '@/components/row/ColComponent.vue';
import InputComponent from '@/components/form/InputComponent.vue';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import ButtonRoute from '@/components/buttons/ButtonRoute.vue';
import MultiSelect from '@/components/form/MultiSelect.vue';
import { useToast } from 'vue-toastification';

import { VMoney } from 'v-money';
import { MONEY } from '@/configs/constants';

const toast = useToast();
export default {
  name: 'ItensNFUpdate',
  components: {
    MultiSelect,
    ButtonRoute,
    ButtonSubmit,
    InputComponent,
    ColComponent,
    RowComponent,
    PainelComponent
  },

  props: {
    id: {
      required: true
    },
    directives: {
      money: VMoney
    }
  },

  created() {
    this.$store.dispatch('changeNamePage', 'Editar NF');
    this.loadItemNF();
    this.checkACL();
  },

  data() {
    return {
      itemsOne: {
        title: 'Editar',
        icon: 'edit'
      },

      itemsTwo: {
        route: '/nf-itens/search',
        icon: 'reply all',
        name: 'Voltar'
      },

      itemsThree: {
        route: '/nf-itens/create',
        icon: 'plus'
      },

      formData: {
        id: '',
        code: '',
        desc: '',
        status: '',
        price: ''
      },

      MONEY,

      edit: false,
      modal_account_sub: false,

      errors: [],
      errorsModal: [],
      viewPage: false,
      sendForm: false,
      showError: false,
      showErrorModal: false,
      create: false,
      editOrUpdate: 0
    };
  },

  methods: {
    loadItemNF() {
      this.$store
        .dispatch('loadItemNF', this.id)
        .then(r => {
          this.formData = r;
        })
        .catch(() => {
          toast.error('Erro desconhecido', { timeout: 2000 });
        });
    },

    submit() {
      if (!this.sendForm) {
        this.sendForm = true;
        this.$store
          .dispatch('updateItensNF', this.formData)

          .then(() => {
            this.showError = false;
            this.sendForm = false;
            this.errors = [];
            toast.success('Atualização efetuado com sucesso', { timeout: 2000 });
            this.loadItemNF(1);
          })
          .catch(r => {
            this.showError = true;
            this.sendForm = false;
            this.errors = r.response.data.errors;
            toast.error('Seu formulário contém erros verificar!', { timeout: 2000 });
          });
      }
    },

    async checkACL() {
      this.$store
        .dispatch('ACLItems', 18)
        .then(r => {
          if (r.indexOf('nf-itens') !== -1) {
            this.viewPage = true;
            if (r.indexOf('nf-itens-edit') !== -1) {
              this.viewPage = true;
            }
            if (r.indexOf('nf-itens-create') !== -1) {
              this.create = true;
            }
          } else {
            this.$router.push({ name: 'home' });
          }
        })
        .catch(() => {
          this.$router.push({ name: 'home' });
        });
    }
  },

  computed: {
    me() {
      return this.$store.state.auth.me;
    },

    selectActiveInative() {
      return this.$store.state.var.selectActiveInative;
    },

    noResultsText() {
      return this.$store.state.var.noResultsText;
    },

    noOptionsText() {
      return this.$store.state.var.noOptionsText;
    }
  }
};
</script>

<style scoped>
.tabs > input,
.tabs > span,
.effect-3 .line {
  width: 50%;
}

#tab-2,
#tab-2 + span,
#tab-2:checked ~ .line {
  left: 50%;
}

.modal-w {
  z-index: 99;
  place-items: center;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal-p {
  width: 80%;
  overflow-y: auto;
  min-height: 800px;
  background-color: #ffffff;
}

.modal-p .btn-close {
  top: 10px;
  right: 10px;
}

.btn-group {
  height: 46px;
}

.modal-add-sub-account .btn-close {
  top: 0;
  right: 0;
  margin: 1%;
  cursor: pointer;
  position: absolute;
}

.modal-add-sub-account {
  top: 50%;
  left: 50%;
  width: 40%;
  z-index: 9999;
  position: fixed;
  min-height: 20%;
  margin-right: -50%;
  padding: 40px 20px;
  overflow-y: auto;
  background: #ffffff;
  transform: translate(-50%, -50%);
}

@media (max-width: 991px) {
  .modal-add-sub-account {
    width: 90%;
  }
}
</style>
