import axios from 'axios';
import { URL_BASE } from '@/configs/constants';

const RESOURCE = 'acquisition';
export default {
  loadConsigneds(context, params) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL_BASE}acquisition-get-consigned`, { params })
        .then(response => {
          context.commit('ACQUISITION', response.data);
          return resolve(response.data);
        })
        .catch(errors => {
          return reject(errors);
        })
        .finally(() => context.commit('LOADER', false));
    });
  },

  loadAcquisitions(context, params) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL_BASE}${RESOURCE}`, { params })
        .then(response => {
          context.commit('ACQUISITION', response.data);
          return resolve(response.data);
        })
        .catch(errors => {
          return reject(errors);
        })
        .finally(() => context.commit('LOADER', false));
    });
  },

  storeAcquisition(context, formData) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL_BASE}${RESOURCE}`, formData)
        .then(response => {
          return resolve(response.data);
        })
        .catch(errors => {
          return reject(errors);
        })
        .finally(() => context.commit('LOADER', false));
    });
  },

  loadAcquisition(context, id) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL_BASE}${RESOURCE}/${id}`)
        .then(response => {
          return resolve(response.data);
        })
        .catch(errors => {
          return reject(errors);
        })
        .finally(() => context.commit('LOADER', false));
    });
  },

  updateAcquisition(context, formData) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .put(`${URL_BASE}${RESOURCE}/${formData.id}`, formData)
        .then(response => {
          return resolve(response.data);
        })
        .catch(errors => {
          return reject(errors);
        })
        .finally(() => context.commit('LOADER', false));
    });
  },

  destroyAcquisition(context, id) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .delete(`${URL_BASE}${RESOURCE}/${id}`)
        .then(response => {
          return resolve(response.data);
        })
        .catch(errors => {
          return reject(errors);
        })
        .finally(() => context.commit('LOADER', false));
    });
  },

  getProduct(context, formData) {
    context.commit('LOADER', true);
    // Cria uma instância de URLSearchParams e adiciona os parâmetros
    const params = new URLSearchParams();
    if (formData.code) params.append('code', formData.code);
    if (formData.name) params.append('name', formData.name);

    // Faz a requisição para a API
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL_BASE}acquisition-get-product?${params.toString()}`)
        .then(response => {
          return resolve(response.data);
        })
        .catch(error => {
          return reject(error);
        })
        .finally(() => context.commit('LOADER', false));
    });
  },

  addProduct(context, formData) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL_BASE}acquisition-add-product`, formData)
        .then(response => {
          return resolve(response.data);
        })
        .catch(error => {
          return reject(error);
        })
        .finally(() => context.commit('LOADER', false));
    });
  },

  destroyProduct(context, id) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .delete(`${URL_BASE}acquisition-delete-product/${id}`)
        .then(response => {
          return resolve(response.data);
        })
        .catch(error => {
          return reject(error);
        })
        .finally(() => context.commit('LOADER', false));
    });
  },

  updateStatus(context, params) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .put(`${URL_BASE}acquisition-update-status/${params.id}`, params)
        .then(r => {
          resolve(r);
        })
        .catch(errors => reject(errors))
        .finally(() => context.commit('LOADER', false));
    });
  }
};
