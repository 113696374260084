<template>
  <painel-component :items="itemsOne">
    <form>
      <row-component>
        <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
          <label>Referência:</label>
          <input-component
            class="text-uppercase"
            @input="filterDataRef"
            v-model="formData.referencia"
            placeholder="Referência"
          />
        </col-component>

        <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
          <label>Descrição:</label>
          <input-component @input="filterDataDesc" v-model="formData.descricao" placeholder="Descrição" />
        </col-component>

        <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
          <label>Categoria:</label>
          <input-component @input="filterDataCat" v-model="formData.categoria" placeholder="Categoria" />
        </col-component>

        <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
          <label>Página:</label>
          <MultiSelect v-model="formData.pagina" :options="selectPage" placeholder="Página" />
        </col-component>

        <!--<col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Status:</label>
                    <MultiSelect v-model="formData.status" placeholder="Status" :options="selectActiveInative"/>
                </col-component>-->
      </row-component>

      <row-component>
        <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
          <button-submit @click="submit" icon="search" name="Pesquisar" />
        </col-component>
      </row-component>
    </form>

    <div class="bg-body-secondaryd">
      <div class="bg-white">
        <div class="__table">
          <table class="table table-striped" v-if="viewPage">
            <thead>
              <tr>
                <th scope="col" style="width: 10%">Referência</th>
                <th scope="col" style="width: 25%">Descrição</th>
                <th scope="col" style="width: 15%">Categoria</th>
                <th scope="col" style="width: 10%">Peso</th>
                <th scope="col" style="width: 10%">Estoque</th>
                <th scope="col" style="width: 10%">Status</th>
                <th scope="col" style="width: 10%">Ações</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(data, index) in data" :key="index">
                <td data-label="Referência">{{ data.referencia }}</td>
                <td data-label="Descrição">{{ data.descricao }}</td>
                <td data-label="Categoria">
                  ({{ data.id_hierarquia_produto.id_hierarquia_produto }}) {{ data.id_hierarquia_produto.nome }}
                </td>
                <td data-label="Peso">{{ data.peso }}</td>
                <td data-label="Estoque">{{ data.qtd_estoque }}</td>
                <td data-label="Status">{{ data.ativo ? 'Ativo' : 'Inativo' }}</td>
                <td data-label="Ações">
                  <button-show :id="data.id" route="commerce-product-show" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import ColComponent from '@/components/row/ColComponent';
import InputComponent from '@/components/form/InputComponent';
import MultiSelect from '@/components/form/MultiSelect';
import ButtonSubmit from '@/components/buttons/ButtonSubmit';
import ButtonShow from '@/components/buttons/ButtonShow';

import { useToast } from 'vue-toastification';

const toast = useToast();
export default {
  name: 'CommerceProductSearch',
  components: {
    ButtonShow,
    ButtonSubmit,
    MultiSelect,
    InputComponent,
    ColComponent,
    RowComponent,
    PainelComponent
  },

  created() {
    this.$store.dispatch('changeNamePage', 'Ecommerce Produto');
    this.loadItems(1);
    this.checkACL();
  },

  data() {
    return {
      itemsOne: {
        icon: 'list alternate',
        title: 'Pesquisar'
      },

      formData: {
        pagina: '',
        status: '',
        referencia: '',
        descricao: '',
        categoria: ''
      },

      data: [],

      viewPage: false,

      totalPage: ''
    };
  },

  methods: {
    submit() {
      this.loadItems(1);
      this.searchSubmit = true;
    },

    loadItems(page) {
      if (!this.searchSubmit) {
        this.$store
          .dispatch('loadCommerceProducts', { ...this.params, page })
          .then(r => {
            this.data = r.products.original.registros;
            this.totalPage = r.products.original.totalPaginas;
            this.pagesSelect();

            if (this.searchSubmit) {
              this.searchSubmit = false;
              if (this.data.length === 0) {
                toast.info('Nada foi encontrado com os parâmetros informados!', { timeout: 2000 });
              }
            }
          })
          .catch(() => {
            this.searchSubmit = false;
          });
      }
    },

    async checkACL() {
      this.$store
        .dispatch('ACLItems', 16)
        .then(r => {
          if (r.indexOf('bw-product') !== -1) {
            this.viewPage = true;
          } else {
            this.$router.push({ name: 'home' });
          }
        })
        .catch(() => {
          this.$router.push({ name: 'home' });
        });
    },

    pagesSelect() {
      const pageArr = [];
      for (let i = 0; i < this.totalPage; i++) {
        pageArr.push({
          label: `Página ${i + 1}`,
          value: i + 1
        });
      }

      return pageArr;
    },

    filterDataRef() {
      if (this.formData.referencia === '') this.loadItems(1);
      else
        this.data = this.data.filter(obj =>
          obj.referencia.toLowerCase().includes(this.formData.referencia.toLowerCase())
        );
    },

    filterDataDesc() {
      if (this.formData.descricao === '') this.loadItems(1);
      else
        this.data = this.data.filter(obj =>
          obj.descricao.toLowerCase().includes(this.formData.descricao.toLowerCase())
        );
    },

    filterDataCat() {
      if (this.formData.categoria === '') this.loadItems(1);
      else
        this.data = this.data.filter(obj =>
          obj.id_hierarquia_produto.nome.toLowerCase().includes(this.formData.categoria.toLowerCase())
        );
    }
  },

  computed: {
    // selectActiveInative() {
    //     return this.selectActiveInativeArr()
    // },

    // selectActiveInative() {
    //     return this.$store.state.var.selectActiveInative
    // },

    selectPage() {
      return this.pagesSelect();
    },

    object() {
      return this.$store.state.e_commerce_product.items;
    },

    params() {
      return {
        pagina: this.formData.pagina,
        status: this.formData.status
      };
    }
  }
};
</script>

<style scoped>
::placeholder {
  text-transform: capitalize;
}
</style>
