<template>
  <painel-component :items="itemsOne">
    <form>
      <row-component>
        <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
          <label>Código:</label>
          <input-component v-model="formDataSearch.code" placeholder="Código" />
        </col-component>
        <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <label>Consultora:</label>
          <MultiSelect v-model="formDataSearch.creator_user_id" :options="clients" />
        </col-component>
        <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
          <label>Status:</label>
          <MultiSelect v-model="formDataSearch.status" :options="selectOpenOrClose" placeholder="Status" />
        </col-component>
      </row-component>

      <row-component>
        <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <button-submit class="me-1" @click="submit" icon="search" name="Pesquisar" />
        </col-component>
      </row-component>
    </form>

    <div class="bg-body-secondary container-style" v-for="(data, index) in object.data" :key="index">
      <div class="body-header mt-3 ml-2">
        <div class="user-creater">
          <img src="../../assets/avatar.png" alt="profile_img" />
          <div>
            <h5>Consultora:</h5>
            <h5 class="">{{ data.user_creater }}</h5>
          </div>

          <h5 class="text-dark">
            <span class="badge rounded-pill text-bg-dark" v-if="data.status == 1">Aberto</span>
            <span class="badge rounded-pill text-bg-dark" v-if="data.status == 2">Fechado</span>
          </h5>
        </div>

        <div class="hour">
          <button class="d-flex align-items-baseline border-0 bg-transparent">
            <i class="icon calendar alternate text-success"></i>
            <p>{{ data.dateHourOpen }}</p>
          </button>

          <button v-if="data.status == 2" class="d-flex align-items-baseline border-0 bg-transparent">
            <i class="icon calendar alternate"></i>
            <p>{{ data.dateHourClose }}</p>
          </button>
        </div>
      </div>

      <div class="card-infos mb-3">
        <RowComponent>
          <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
            <div class="card-home">
              <div class="desc">
                <p>Dinheiro</p>
                <p>R$ {{ data.balanceMoney }}</p>
              </div>
              <div class="icon">
                <i class="money bill alternate icon"></i>
              </div>
            </div>
          </col-component>

          <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
            <div class="card-home">
              <div class="desc">
                <p>PIX</p>
                <p>R$ {{ data.balancePix }}</p>
              </div>
              <div class="icon">
                <i class="dollar sign icon"></i>
              </div>
            </div>
          </col-component>

          <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
            <div class="card-home">
              <div class="desc">
                <p>Cartão de Crédito</p>
                <p>R$ {{ data.balanceCredit }}</p>
              </div>
              <div class="icon">
                <i class="credit card icon"></i>
              </div>
            </div>
          </col-component>

          <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
            <div class="card-home">
              <div class="desc">
                <p>Cartão de Débito</p>
                <p>R$ {{ data.balanceDebit }}</p>
              </div>
              <div class="icon">
                <i class="credit card icon"></i>
              </div>
            </div>
          </col-component>
        </RowComponent>
      </div>

      <div class="btn-show pt-0 px-0 mb-0">
        <div></div>
        <button-show class="show-component" :id="data.id" route="pdv-box-dashboard-show" />
      </div>
    </div>

    <button-pagination :pagination="object" :offset="3" @paginate="loadItems" />
  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import ColComponent from '@/components/row/ColComponent';
import InputComponent from '@/components/form/InputComponent';
import MultiSelect from '@/components/form/MultiSelect';
import ButtonSubmit from '@/components/buttons/ButtonSubmit';
import ButtonShow from '@/components/buttons/ButtonShow.vue';
import ButtonPagination from '@/components/buttons/ButtonPagination';

import { useToast } from 'vue-toastification';

const toast = useToast();
export default {
  name: 'UserSearch',

  components: {
    PainelComponent,
    RowComponent,
    ColComponent,
    InputComponent,
    MultiSelect,
    ButtonSubmit,
    ButtonShow,
    ButtonPagination
  },

  created() {
    this.$store.dispatch('changeNamePage', 'Dashboard Caixa');
    this.$store.dispatch('loadSelectUserGroup');
    this.loadItems();
    this.checkACL();
    this.loadClients();
  },

  data() {
    return {
      itemsOne: {
        title: 'Pesquisar',
        icon: 'dashboard'
      },

      buttonCheck: {
        show: false
      },

      formData: {},

      formDataSearch: {
        status: 1
      },

      viewPage: false,
      searchSubmit: false,

      clients: []
    };
  },

  methods: {
    submit() {
      this.loadItems(1);
      this.searchSubmit = true;
    },

    loadItems(page) {
      if (!this.searchSubmit) {
        this.$store
          .dispatch('loadDashboardBoxHistorics', { ...this.params, page })
          .then(r => {
            if (this.searchSubmit) {
              this.searchSubmit = false;
              if (r.data.length === 0) {
                toast.info('Nada foi encontrado com os parâmetros informados!', { timeout: 2000 });
              }
            }
          })
          .catch(() => {
            this.searchSubmit = false;
          });
      }
    },

    loadClients() {
      this.$store
        .dispatch('loadClientList')
        .then(r => {
          this.clients = r.map(client => ({
            label: client.name,
            value: client.value,
            icon: client.icon
          }));
        })
        .catch(e => {
          this.showError = true;
          window.scroll(0, 0);
          this.errors = e.response.data.errors;
        });
    },

    async checkACL() {
      this.$store
        .dispatch('ACLItems', 17)
        .then(r => {
          if (r.indexOf('pdv-box-dashboard') !== -1) {
            this.viewPage = true;

            if (r.indexOf('pdv-box-dashboard-search') !== -1) {
              this.buttonCheck.create = true;
            }
            if (r.indexOf('pdv-box-dashboard-show') !== -1) {
              this.buttonCheck.create = true;
            }
          } else {
            this.$router.push({ name: 'home' });
          }
        })
        .catch(() => {
          this.$router.push({ name: 'home' });
        });
    },

    statusString(value) {
      if (parseInt(value) === 1) return 'Ativo';
      return 'Inativo';
    }
  },

  computed: {
    me() {
      return this.$store.state.auth.me;
    },

    selectActiveInative() {
      return this.$store.state.var.selectActiveInative;
    },

    selectUserGroup() {
      return this.$store.state.var.selectUserGroup;
    },

    object() {
      return this.$store.state.box_dashboard.items;
    },

    selectOpenOrClose() {
      return this.$store.state.var.selectOpenOrClose;
    },

    params() {
      return {
        code: this.formDataSearch.code,
        creator_user_id: this.formDataSearch.creator_user_id,
        status: this.formDataSearch.status
      };
    }
  }
};
</script>

<style scoped>
.container-style {
  border: 1px solid #000;
  margin: 0 0 25px 0;
}
.body-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
}
.user-creater {
  display: flex;
}
.user-creater img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.user-creater div {
  margin-left: 15px;
}

.payment-option {
  height: 100px;
  border: none;
  border-radius: 5px;
  background-color: #ffffff;
}

.payment-option div {
  display: flex;
  justify-content: space-between;
  padding: 25px;
}

.payment-option .icon-card {
  font-size: 38px;
}

.btn-show {
  display: flex;
  justify-content: space-between;
}

.no-padding {
  margin: 0;
  padding: 0;
  width: 24%;
}

i.icon {
  margin: 0 25px 0 0;
  width: 0;
  height: 0;
  text-align: center;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  backface-visibility: hidden;
}

.hour button {
  height: 25px;
}

.card-infos {
  margin: 20px;
}

.card-infos .card-home {
  border: 0;
  width: 100%;
  height: 95px;
  display: flex;
  border-radius: 5px;
  align-items: center;
  background: #ffffff;
  justify-content: space-between;
}

.card-infos .card-home .icon {
  width: 0%;
  background-color: transparent;
  color: #000000;
  font-size: 3.5rem;
  margin-right: 85px;
}

.card-infos .card-home .desc {
  padding: 0 20px;
}

.card-infos .card-home .desc p {
  margin: 0;
  color: #ababab;
  font-size: 16px;
  text-transform: uppercase;
}

.card-infos .card-home .desc p:last-child {
  margin: 0;
  color: #000000;
  font-weight: bold;
}

.credit .card .icon {
  background-color: red;
}

@media (max-width: 1290px) {
  .card-infos .card-home .desc {
    padding: 0 0 0 10px;
  }

  .card-infos .card-home .desc p {
    font-size: 12px;
  }

  .card-infos .card-home .icon {
    font-size: 3rem;
  }
}

@media (max-width: 991px) {
  .headerTitle {
    flex-direction: column;
    height: auto;
    padding: 10px;
    grid-gap: 10px 0;
  }

  .card-infos .card-home {
    width: 100%;
  }

  .card-infos .card-home .desc p {
    margin: 0;
    font-size: 16px;
  }

  .card-infos .card-home .desc {
    padding: 20px;
  }
}

@media (max-width: 620px) {
  .body-header {
    flex-direction: column;
  }
}

@media (max-width: 428px) {
  .card-infos .card-home .desc p {
    margin: 0;
    font-size: 20px;
  }
}
</style>
