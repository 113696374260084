export default {
    icon: 'list',
    name: 'Consignado',
    subLinks: [

        {
            name: 'Aquisição',
            route: '/acquisition/search',
            pagePermissions: ['acquisition-search', 'acquisition-create', 'acquisition-edit', 'acquisition-delete']
        },

        {
            name: 'Cadastro',
            route: '/consigned/search',
            pagePermissions: ['consigned-search', 'consigned-create', 'consigned-edit', 'consigned-delete']
        }

    ],

    pageNavCheck: [
        'consigned-search',
        'consigned-create',
        'consigned-edit',
        'consigned-delete',
        'acquisition-search',
        'acquisition-create',
        'acquisition-edit',
        'acquisition-delete'
    ]
};
