<template>
    <painel-component :items="itemsOne">
        <error-component :errors="errors" v-if="showError"/>

        <form v-if="viewPage">
            <row-component>
                <col-component class-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12"
                               :class="{'formError' : errors.name}">
                    <label>Nome:</label>
                    <input-component v-model="formData.name"/>
                </col-component>

                <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                               :class="{'formError' : errors.status}">
                    <label>Status:</label>
                    <MultiSelect v-model="formData.status" :options="selectActiveInative"/>
                </col-component>
            </row-component>

            <row-component>
                <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                               :class="{'formError' : errors.minimum_stock_store}">
                    <label>Estoque mínimo loja:</label>
                    <input-component v-model="formData.minimum_stock_store" type="number"/>
                </col-component>

                <col-component class-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                               :class="{'formError' : errors.initial_inventory_store}">
                    <label>Estoque inicial loja:</label>
                    <input-component v-model="formData.initial_inventory_store" type="number"/>
                </col-component>

                <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                               :class="{'formError' : errors.kilo}">
                    <label>Peso (GR):</label>
                    <div class="position-relative">
                        <p class="position-absolute top-50 start-0 translate-middle-y"
                           style="margin-left: 10px">
                            {{ formData.kilo }}
                        </p>
                        <input-component autocomplete="off" type="number" id="kilos" @keyup="funcOne"
                                         style="color: #FFFFFF !important;"/>
                    </div>
                </col-component>


              <col-component class-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                             :class="{'formError' : errors.cost_price}">
                <label>Preço custo:</label>
                <input-component v-model="formData.cost_price" v-money="MONEY"/>
              </col-component>


                <col-component class-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                               :class="{'formError' : errors.price}">
                    <label>Preço Venda:</label>
                    <input-component v-model="formData.price" v-money="MONEY"/>
                </col-component>
            </row-component>

            <row-component>
                <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                               :class="{'formError' : errors.category_id}">
                    <label>Categoria:</label>
                    <MultiSelect v-model="formData.category_id" :options="selectCategory"/>
                </col-component>

                <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                               :class="{'formError' : errors.subcategory_id}">
                    <label>Subcategoria:</label>
                    <MultiSelect v-model="formData.subcategory_id" :options="selectSubcategory"/>
                </col-component>

                <col-component class-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                               :class="{'formError' : errors.height}">
                    <label>Altura (cm):</label>
                    <input-component v-money="centimeters" v-model="formData.height"/>
                </col-component>

                <col-component class-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                               :class="{'formError' : errors.width}">
                    <label>Largura (cm):</label>
                    <input-component v-money="centimeters" v-model="formData.width"/>
                </col-component>

                <col-component class-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                               :class="{'formError' : errors.length}">
                    <label>Comprimento (cm):</label>
                    <input-component v-money="centimeters" v-model="formData.length"/>
                </col-component>
            </row-component>

            <row-component>
                <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                               :class="{'formError' : errors.sector}">
                    <label>Setor:</label>
                    <input-component v-model="formData.sector"/>
                </col-component>

                <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                               :class="{'formError' : errors.column}">
                    <label>Coluna:</label>
                    <input-component v-model="formData.column" type="number"/>
                </col-component>

                <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                               :class="{'formError' : errors.shelf}">
                    <label>Prateleira:</label>
                    <input-component v-model="formData.shelf" type="number"/>
                </col-component>
            </row-component>

            <row-component>
                <col-component class-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                               :class="{'formError' : errors.desc}">
                    <label>Descrição:</label>
                    <text-area v-model="formData.desc" rows="6"/>
                </col-component>
            </row-component>

            <row-component>
                <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-submit :disabled="sendForm" @click="submit" class="w-100" icon="plus" name="Adicionar"/>
                </col-component>

                <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itemsTwo"/>
                </col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import ColComponent from "@/components/row/ColComponent";
import InputComponent from "@/components/form/InputComponent";
import MultiSelect from "@/components/form/MultiSelect";
import TextArea from "@/components/form/TextArea";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import ErrorComponent from "@/components/alerts/ErrorComponent";

import {VMoney} from "v-money"
import {MONEY} from "@/configs/constants";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "ProductCreate",

    components: {
        ErrorComponent,
        ButtonRoute,
        ButtonSubmit,
        TextArea,
        MultiSelect,
        InputComponent,
        ColComponent,
        RowComponent,
        PainelComponent
    },

    directives: {
        money: VMoney
    },

    created() {
        this.$store.dispatch("changeNamePage", "Produto")
        this.$store.dispatch("loadSelectCategory")
        this.$store.dispatch("loadSelectSubcategory")
        this.checkACL()
    },


    data() {
        return {
            itemsOne: {
                title: "Adicionar",
                icon: "pen square"
            },

            itemsTwo: {
                route: "/product/search",
                icon: "reply all",
                name: "Voltar"
            },

            formData: {
                name: "",
                status: "",
                cost_price:"",
                price: "",
                minimum_stock_store: "",
                minimum_stock_shop: "",
                initial_inventory_store: "",
                initial_inventory_shop: "",
                category_id: "",
                subcategory_id: "",
                kilo: "",
                height: "",
                width: "",
                length: "",
                sector: "",
                shelf: "",
                column: "",
                desc: ""
            },

            MONEY,

            centimeters: {
                decimal: '.',
                thousands: ',',
                prefix: '',
                suffix: '',
                precision: 2,
                masked: false /* doesn't work with directive */
            },

            weight: {
                decimal: '',
                thousands: '',
                prefix: '',
                suffix: '',
                precision: 2,
                masked: false /* doesn't work with directive */
            },

            errors: [],
            viewPage: false,
            sendForm: false,
            showError: false,
        }
    },

    methods: {
        submit() {
         if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('storeProduct', this.formData)
                    .then((r) => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success('Cadastro efetuado com sucesso', {timeout: 1500})
                        this.$router.push({name: 'product-edit', params: {id: r}})
                    }).catch((e) => {
                    this.showError = true
                    this.sendForm = false
                    window.scroll(0, 0)
                    this.errors = e.response.data.errors
                })
            }
        },


        funcOne(event) {
            let value = event.target.value
            value = parseInt(0 + value);
            return this.formData.kilo = this.kiloMask(this.pad(value, 5))
        },

        kiloMask(v) {
            v = v.replace(/^(\d{3})(\d{1})/, '$1,$2')
            return v
        },

        pad(str, max) {
            str = str.toString();
            str = str.length < max ? this.pad("0" + str, max) : str; // zero à esquerda
            str = str.length > max ? str.substring(0, max) : str; // máximo de caracteres
            return str;
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 8)
                .then((r) => {
                    if (r.indexOf('product-create') !== -1) {
                        this.viewPage = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        selectProductClassification() {
            return this.$store.state.var.selectProductClassification
        },

        selectSubcategory() {
            return this.$store.state.var.selectSubcategory
        },

        selectCategory() {
            return this.$store.state.var.selectCategory
        },
    }
}
</script>