<template>
  <painel-component :items="itemsOne">
    <form>
      <row-component>
        <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
          <label>Cliente:</label>
          <input-component @input="filterData" v-model="formData.cliente" placeholder="Cliente" />
        </col-component>

        <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
          <label>CNPJ / CPF:</label>
          <input-component v-model="formData.cpf_cnpj" placeholder="CNPJ / CPF" />
        </col-component>

        <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
          <label>Status:</label>
          <MultiSelect v-model="formData.status" placeholder="Status" :options="selectActiveInative" />
        </col-component>

        <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
          <label>Página:</label>
          <MultiSelect v-model="formData.pagina" :options="selectPage" placeholder="Página" />
        </col-component>
      </row-component>

      <row-component>
        <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
          <button-submit @click="submit" icon="search" name="Pesquisar" />
        </col-component>
      </row-component>
    </form>

    <div class="bg-body-secondaryd">
      <div class="bg-white">
        <div class="__table">
          <table class="table table-striped" v-if="viewPage">
            <thead>
              <tr>
                <th scope="col" style="width: 2%">ID</th>
                <th scope="col" style="width: 15%">Nome</th>
                <th scope="col" style="width: 10%">CPF/CNPJ</th>
                <th scope="col" style="width: 10%">Email</th>
                <th scope="col" style="width: 5%">Telefone</th>
                <th scope="col" style="width: 5%">Cadastro</th>
                <th scope="col" style="width: 5%">Status</th>
                <th scope="col" style="width: 5%">Ações</th>
              </tr>
            </thead>

            <tbody v-for="(data, index) in object.registros" :key="index">
              <tr>
                <td data-label="ID">{{ data.id }}</td>
                <td data-label="Nome">{{ data.nome }}</td>
                <td data-label="CPF/CNPJ">{{ formatRecords(data.cpf_cnpj) }}</td>
                <td data-label="Email">{{ data.email }}</td>
                <td data-label="Telefone">{{ data.fone }}</td>
                <td data-label="Cadastro">{{ formatDate(data.data_cadastro) }}</td>
                <td data-label="Status">{{ statusString(data.status) }}</td>
                <td data-label="Ações">
                  <button-show :id="data.id" route="commerce-client-show" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import ColComponent from '@/components/row/ColComponent';
import InputComponent from '@/components/form/InputComponent';
import MultiSelect from '@/components/form/MultiSelect';
import ButtonSubmit from '@/components/buttons/ButtonSubmit';
import ButtonShow from '@/components/buttons/ButtonShow';

import { useToast } from 'vue-toastification';

const toast = useToast();
export default {
  name: 'CommerceClientSearch',
  components: {
    ButtonShow,
    ButtonSubmit,
    MultiSelect,
    InputComponent,
    ColComponent,
    RowComponent,
    PainelComponent
  },

  created() {
    this.$store.dispatch('changeNamePage', 'Ecommerce Cliente');
    this.loadItems(1);
    this.checkACL();
  },

  data() {
    return {
      itemsOne: {
        icon: 'list alternate',
        title: 'Pesquisar'
      },

      formData: {
        cliente: '',
        cpf_cnpj: '',
        status: '',
        pagina: ''
      },

      viewPage: false,
      totalPage: ''
    };
  },

  methods: {
    submit() {
      this.loadItems(1);
      this.searchSubmit = true;
    },

    loadItems(page) {
      if (!this.searchSubmit) {
        this.$store
          .dispatch('loadCommerceCustomers', { ...this.params, page })
          .then(r => {
            if (this.searchSubmit) {
              this.searchSubmit = false;
              if (r.registros.length === 0) {
                toast.info('Nada foi encontrado com os parâmetros informados!', { timeout: 2000 });
              }
            }
            this.totalPage = r.totalPaginas;
            this.pagesSelect();
          })
          .catch(() => {
            this.searchSubmit = false;
          });
      }
    },

    async checkACL() {
      this.$store
        .dispatch('ACLItems', 16)
        .then(r => {
          if (r.indexOf('bw-client') !== -1) {
            this.viewPage = true;
          } else {
            this.$router.push({ name: 'home' });
          }
        })
        .catch(() => {
          this.$router.push({ name: 'home' });
        });
    },

    formatDate(value) {
      let date = value.split(' ')[0];
      date = date.split('-');
      return `${date[2]}/${date[1]}/${date[0]}`;
    },

    formatRecords(value) {
      value = value.replace(/\D/g, '');
      if (value.length === 11)
        /* eslint-disable-next-line */
        return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '\$1.\$2.\$3-\$4');

      /* eslint-disable-next-line */
      return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '\$1.\$2.\$3/\$4-\$5');
    },

    statusString(value) {
      if (parseInt(value) === 0) return 'Inativo';
      return 'Ativo';
    },

    selectActiveInativeArr() {
      return [
        { label: 'Inativo', value: 0 },
        { label: 'Ativo', value: 1 }
      ];
    },

    pagesSelect() {
      const pageArr = [];
      for (let i = 0; i < this.totalPage; i++) {
        pageArr.push({
          label: `Página ${i + 1}`,
          value: i + 1
        });
      }

      return pageArr;
    },

    filterData() {
      if (this.formData.cliente === '') this.loadItems(1);
      else
        this.object.registros = this.object.registros.filter(obj =>
          obj.nome.toLowerCase().includes(this.formData.cliente.toLowerCase())
        );
    }
  },

  computed: {
    selectActiveInative() {
      return this.selectActiveInativeArr();
    },

    selectPage() {
      return this.pagesSelect();
    },

    object() {
      return this.$store.state.e_commerce_client.items;
    },

    params() {
      return {
        cpf_cnpj: this.formData.cpf_cnpj,
        status: this.formData.status,
        pagina: this.formData.pagina
      };
    }
  }
};
</script>
