import axios from 'axios';
import { URL_BASE } from '@/configs/constants';

// Mudar rota
const RESOURCE = 'nf-itens';

export default {
  loadItensNFs(context, params) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL_BASE}${RESOURCE}`, { params })
        .then(response => {
          context.commit('LOAD_NF_ITENS', response.data);
          return resolve(response.data);
        })
        .catch(errors => {
          return reject(errors);
        })
        .finally(() => context.commit('LOADER', false));
    });
  },

  loadItemNF(context, id) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL_BASE}${RESOURCE}/${id}`)
        .then(response => resolve(response.data))
        .catch(e => reject(e))
        .finally(() => context.commit('LOADER', false));
    });
  },

  storeItemNF(context, formData) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL_BASE}${RESOURCE}`, formData)
        .then(response => {
          return resolve(response.data);
        })
        .catch(errors => {
          return reject(errors);
        })
        .finally(() => context.commit('LOADER', false));
    });
  },

  updateItensNF(context, formData) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .put(`${URL_BASE}nf-itens/${formData.id}`, formData)
        .then(r => {
          resolve(r);
        })
        .catch(errors => reject(errors))
        .finally(() => context.commit('LOADER', false));
    });
  },

  destroyItensNF(context, id) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .delete(`${URL_BASE}${RESOURCE}/${id}`)
        .then(response => resolve(response.data))
        .catch(errors => reject(errors))
        .finally(() => context.commit('LOADER', false));
    });
  },

  loadItemListSelect(context) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL_BASE}nf-itens-list`)
        .then(response => {
          context.commit('LOAD_NF_ITENS', response.data);
          return resolve(response.data);
        })
        .catch(errors => {
          return reject(errors);
        });
    });
  },

  loadItensNfGenerate(context, id) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL_BASE}nf-itens-get-price/${id}`)
        .then(r => resolve(r))
        .catch(errors => reject(errors))
        .finally(() => context.commit('LOADER', false));
    });
  }
};
