<template>
  <painel-component :items="itemsOne">
    <form>
      <row-component>
        <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
          <label>Código:</label>
          <input-component v-model="formData.code" placeholder="Código" />
        </col-component>

        <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
          <label>Nome:</label>
          <input-component v-model="formData.name" placeholder="Nome" />
        </col-component>

        <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
          <label>Status:</label>
          <MultiSelect v-model="formData.status" placeholder="Status" :options="selectActiveInative" />
        </col-component>
      </row-component>

      <row-component>
        <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <label>Categoria:</label>
          <MultiSelect v-model="formData.category_id" placeholder="Categoria" :options="selectCategory" />
        </col-component>

        <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <label>Subcategoria:</label>
          <MultiSelect v-model="formData.subscategory_id" placeholder="Subcategoria" :options="selectSubcategory" />
        </col-component>
      </row-component>

      <row-component>
        <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <button-submit class="me-1" @click="submit" icon="search" name="Pesquisar" />
          <button-route v-if="buttonCheck.create" :items="itemsTwo" />
        </col-component>
      </row-component>
    </form>

    <div class="bg-body-secondaryd">
      <div class="bg-white">
        <div class="__table">
          <table class="table table-striped" v-if="viewPage">
            <thead>
              <tr>
                <th scope="col" style="width: 30%">Nome</th>
                <th scope="col" style="width: 20%">Referência</th>
                <th scope="col" style="width: 20%">Estoque Loja</th>
                <!-- <th scope="col" style="width: 10%">Estoque e-commerce</th> -->
                <th scope="col" style="width: 15%">Preço Custo</th>
                <th scope="col" style="width: 15%">Preço Venda</th>
                <th scope="col" style="width: 20%" v-if="buttonCheck.edit">Ações</th>
              </tr>
            </thead>

            <tbody v-for="(data, index) in object.data" :key="index" class="position-relative">
              <tr>
                <td data-label="Nome" class="name">
                  <img class="image-desc" height="100" width="100" :src="data.photo" alt="" />
                  {{ data.name }}
                </td>
                <td data-label="Referência">{{ data.code }}</td>
                <td data-label="Estoque Loja">
                  {{ data.initial_inventory_store }}
                </td>
                <!-- <td data-label="Estoque e-commerce">{{ data.initial_inventory_shop }}</td> -->
                <td data-label="Preço Custo">R$ {{ data.cost_price }}</td>
                <td data-label="Preço Venda">R$ {{ data.price }}</td>
                <td data-label="Ações" v-if="buttonCheck.edit">
                  <button-edit v-if="buttonCheck.edit" :id="data.id" route="product-edit" />
                  <span class="position-absolute top-0 end-0 rounded-0 badge bg-black" v-if="parseInt(data.bw) === 2">
                    Sincronizado BW
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <button-pagination :pagination="object" :offset="3" @paginate="loadItems" />
  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import ColComponent from '@/components/row/ColComponent';
import InputComponent from '@/components/form/InputComponent';
import MultiSelect from '@/components/form/MultiSelect';
import ButtonSubmit from '@/components/buttons/ButtonSubmit';
import ButtonRoute from '@/components/buttons/ButtonRoute';
import ButtonEdit from '@/components/buttons/ButtonEdit';
import ButtonPagination from '@/components/buttons/ButtonPagination';

import { useToast } from 'vue-toastification';

const toast = useToast();
export default {
  name: 'ProductSearch',

  components: {
    ButtonPagination,
    ButtonEdit,
    ButtonRoute,
    ButtonSubmit,
    MultiSelect,
    InputComponent,
    ColComponent,
    RowComponent,
    PainelComponent
  },

  created() {
    this.$store.dispatch('changeNamePage', 'Produto');
    this.$store.dispatch('loadSelectCategory');
    this.$store.dispatch('loadSelectSubcategory');
    this.loadItems(1);
    this.checkACL();
  },

  data() {
    return {
      itemsOne: {
        title: 'Pesquisar',
        icon: 'list alternate'
      },

      itemsTwo: {
        route: '/product/create',
        icon: 'plus',
        name: 'Adicionar'
      },

      buttonCheck: {
        create: false,
        edit: false
      },

      formData: {
        code: '',
        name: '',
        status: '',
        category_id: '',
        subscategory_id: ''
      },

      viewPage: false
    };
  },

  methods: {
    submit() {
      this.loadItems(1);
      this.searchSubmit = true;
    },

    loadItems(page) {
      if (!this.searchSubmit) {
        this.$store
          .dispatch('loadProducts', { ...this.params, page })
          .then(r => {
            if (this.searchSubmit) {
              this.searchSubmit = false;
              if (r.data.length === 0) {
                toast.info('Nada foi encontrado com os parâmetros informados!', { timeout: 2000 });
              }
            }
          })
          .catch(() => {
            this.searchSubmit = false;
          });
      }
    },

    async checkACL() {
      this.$store
        .dispatch('ACLItems', 8)
        .then(r => {
          if (r.indexOf('product-search') !== -1) {
            this.viewPage = true;

            if (r.indexOf('product-create') !== -1) {
              this.buttonCheck.create = true;
            }

            if (r.indexOf('product-edit') !== -1) {
              this.buttonCheck.edit = true;
            }
          } else {
            this.$router.push({ name: 'home' });
          }
        })
        .catch(() => {
          this.$router.push({ name: 'home' });
        });
    },

    statusString(value) {
      if (parseInt(value) === 1) return 'Ativo';
      return 'Inativo';
    }
  },

  computed: {
    selectActiveInative() {
      return this.$store.state.var.selectActiveInative;
    },

    selectCategory() {
      return this.$store.state.var.selectCategory;
    },

    selectSubcategory() {
      return this.$store.state.var.selectSubcategory;
    },

    object() {
      return this.$store.state.product.items;
    },

    params() {
      return {
        page: this.object.current_page,
        code: this.formData.code,
        name: this.formData.name,
        status: this.formData.status,
        category_id: this.formData.category_id,
        subscategory_id: this.formData.subscategory_id
      };
    }
  }
};
</script>

<style scoped>
.bg-black {
  color: #ffffff;
  background: #000000;
}

@media (max-width: 991px) {
  .name {
    padding: 5px;
  }

  .image-desc {
    width: 40px;
    height: 40px;
  }
}
</style>
