<template>
  <div class="modal-w d-grid position-fixed h-100 w-100 start-0 top-0" v-if="NFModalGenerate">
    <NFModalGenerate v-if="NFModalGenerate" @close="closeNFModalGenerate" :text-end="textEnd" />
  </div>

  <painel-component :items="itemsOne">
    <error-component :errors="errors" v-if="showError" />
    <div class="tabs effect-3" v-if="viewPage">
      <input type="radio" id="tab-1" name="tab-effect-3" :class="{ 'mobileTab-client': tabMobile }" checked />
      <span>
        <i class="info circle icon"></i>
        <span>Informações</span>
      </span>

      <input type="radio" id="tab-2" name="tab-effect-3" />
      <span :class="{ hidden: picked !== 2 }">
        <i class="tasks icon"></i>
        <span>Itens</span>
      </span>

      <div class="line ease"></div>

      <div class="tab-content">
        <section id="tab-item-1">
          <div v-if="formData.erroSefaz === 2" class="alert alert-danger alert-dismissible fade show" role="alert">
            <button type="button" class="btn-close" @click="closeAlert" aria-label="Close"></button>
            <div>
              <span><strong>CNPJ Emitente:</strong> {{ formData.erroSefazMessage.cnpj_emitente }}</span> <br />
              <span><strong>Referência:</strong> {{ formData.erroSefazMessage.ref }}</span> <br />
              <span><strong>Status:</strong> {{ formData.erroSefazMessage.status }}</span
              ><br />
              <span><strong>Status Sefaz:</strong> {{ formData.erroSefazMessage.status_sefaz }}</span
              ><br />
              <span><strong>Mensagem Sefaz:</strong> {{ formData.erroSefazMessage.mensagem_sefaz }}</span>
            </div>
          </div>

          <div class="pt-3 text-uppercase d-flex justify-content-between">
            <h5 class="text-dark">
              <span class="badge rounded-pill text-bg-dark mx-1 font-span">N°: {{ formData.ref }}</span>
              <span class="badge rounded-pill text-bg-dark font-span" v-if="formData.status == 1">Em Cadastro</span>
              <span class="badge rounded-pill text-bg-dark font-span" v-if="formData.status == 2">Erro</span>
              <span class="badge rounded-pill text-bg-dark font-span" v-if="formData.status == 3">Emitida</span>
              <span class="badge rounded-pill text-bg-dark font-span" v-if="formData.status == 4">Cancelada</span>
            </h5>

            <div class="button-actions">
              <button-submit
                icon="icon cancel"
                class="mx-1"
                name="Cancelar"
                v-if="formData.status == 3"
                @click="destroyGenerateNF(formData.id)"
              />

              <button-submit
                name="Gerar NF"
                icon="icon plus"
                @click="generateNF"
                v-if="formData.status <= 2"
                :disabled="isDisabled"
              />

              <button-submit
                icon="icon file pdf"
                name="PDF"
                class="mx-1"
                v-if="formData.status > 2"
                @click="openLink(1)"
              />

              <button-submit
                icon="icon file alternate"
                name="XML"
                class="mx-1"
                v-if="formData.status > 2"
                @click="openLink(2)"
              />
            </div>
          </div>

          <form>
            <row-component>
              <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-9 col-sm-12 col-12">
                <label>Cliente:</label>
                <MultiSelect
                  v-model="formData.client_id"
                  :options="clients"
                  placeholder="Cliente"
                  :disabled="isDisabled"
                  @select="loadStoreClientAdress(formData.client_id)"
                />
              </col-component>

              <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-3 col-sm-12 col-12">
                <label>Natureza operação:</label>
                <MultiSelect
                  v-model="formData.nature_operation"
                  :options="selectNaturezaOperacao"
                  :disabled="isDisabled"
                />
              </col-component>
            </row-component>

            <row-component>
              <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                <label>CEP:</label>
                <input-component
                  v-model="formData.cep"
                  v-mask="'#####-###'"
                  @change="searchApiCEP"
                  :disabled="isDisabled"
                />
              </col-component>

              <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                <label>Cidade:</label>
                <input-component v-model="formData.city" :disabled="isDisabled" />
              </col-component>

              <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                <label>Estado:</label>
                <input-component v-model="formData.state" :disabled="isDisabled" />
              </col-component>

              <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                <label>Bairro:</label>
                <input-component v-model="formData.district" :disabled="isDisabled" />
              </col-component>
            </row-component>

            <row-component>
              <col-component class-col="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12">
                <label>Endereço:</label>
                <input-component v-model="formData.address" :disabled="isDisabled" />
              </col-component>

              <col-component class-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                <label>Nº:</label>
                <input-component v-model="formData.number" :disabled="isDisabled" />
              </col-component>
            </row-component>

            <row-component>
              <col-component
                class-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                :class="{ formError: errors.desc }"
              >
                <label>Informações adicionais:</label>
                <text-area v-model="formData.desc" rows="4" :disabled="isDisabled" />
              </col-component>
            </row-component>

            <row-component>
              <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <button-submit
                  class="w-100"
                  :disabled="isDisabled || sendForm"
                  @click="submit"
                  icon="save"
                  name="Salvar"
                />
              </col-component>

              <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <button-route class="w-100" :items="itemsTwo" />
              </col-component>
            </row-component>
          </form>
        </section>

        <section id="tab-item-2">
          <div class="clearfix mb-3">
            <button-submit @click="addBudget" class="float-end" icon="plus" v-if="formData.status == 1" />
          </div>

          <form>
            <row-component class="bg-body-secondary p-3 mb-3" v-for="(data, index) in formData.nf_itens" :key="index">
              <div class="acountin">{{ index + 1 }}</div>
              <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mt-5">
                <label>Item:</label>
                <MultiSelect
                  v-model="data.nf_item_id"
                  :options="itemLists"
                  @select="onSelectItem(data.nf_item_id, index)"
                  :disabled="isDisabled"
                />
              </col-component>

              <col-component class-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mt-5">
                <label>Quantidade:</label>
                <input-component
                  v-model="data.amount"
                  type="number"
                  step="1"
                  placeholder="1"
                  @keyup="calculateTotal(index)"
                  :disabled="isDisabled"
                />
              </col-component>

              <col-component
                class-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mt-5"
                :class="{ formError: errors.price_un }"
              >
                <label>Valor:</label>
                <input-component v-model="data.price_un" v-money="MONEY" @keyup="calculateTotal(index)" />
              </col-component>

              <col-component class-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mt-5">
                <label>Valor total:</label>
                <input-component v-model="data.price_total" v-money="MONEY" readonly />
              </col-component>

              <div class="clearfix">
                <button-delete
                  v-if="index > 0 && formData.status == 1"
                  @click="removeBudget(index)"
                  class="float-end"
                />
              </div>
            </row-component>

            <h4 class="total_price_un">Total: R$ {{ calculateOverallTotal() }}</h4>

            <row-component>
              <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <button-submit class="w-100" @click="submitItens" icon="save" name="Salvar" />
              </col-component>

              <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <button-submit class="w-100 back-to-info" @click="switchToTab(1)" icon="reply all" />
              </col-component>
            </row-component>
          </form>
        </section>
      </div>
    </div>
  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import ColComponent from '@/components/row/ColComponent';
import MultiSelect from '@/components/form/MultiSelect';
import InputComponent from '@/components/form/InputComponent';
import ErrorComponent from '@/components/alerts/ErrorComponent';
import ButtonSubmit from '@/components/buttons/ButtonSubmit';
import ButtonRoute from '@/components/buttons/ButtonRoute';
import ButtonDelete from '@/components/buttons/ButtonDelete';
import TextArea from '@/components/form/TextArea';

// import NFModalGenerateDow from "@/components/modal/NFModalGenerateDow.vue";
import NFModalGenerate from '@/components/modal/NFModalGenerate.vue';

import swal from 'sweetalert';

import { VMoney } from 'v-money';
import { mask } from 'vue-the-mask';
import { MONEY } from '@/configs/constants';

import { useToast } from 'vue-toastification';

const toast = useToast();

export default {
  name: 'GenerateNFUpdate',
  components: {
    NFModalGenerate,
    // NFModalGenerateDow,
    ErrorComponent,
    InputComponent,
    MultiSelect,
    ColComponent,
    RowComponent,
    PainelComponent,
    ButtonRoute,
    ButtonSubmit,
    ButtonDelete,
    TextArea
  },

  props: {
    id: {
      required: true
    },
    directives: {
      mask,
      money: VMoney
    }
  },

  created() {
    this.$store.dispatch('changeNamePage', 'Editar NF');
    this.checkACL();
    this.loadNfGenerate();
    this.loadClients();
    this.loadItemListSelect();
  },

  data() {
    return {
      itemsOne: {
        title: 'Editar',
        icon: 'pen square'
      },

      itemsTwo: {
        route: '/nf-generate/search',
        icon: 'reply all',
        name: 'Voltar'
      },

      formData: {
        id: '',
        client_id: '',
        cep: '',
        city: '',
        state: '',
        district: '',
        address: '',
        complement: '',
        nature_operation: '',
        nf_itens: [],
        ref: '',
        number: '',
        type_doc: '',
        status: [],
        linkPDF: '',
        linkXML: '',
        desc: '',
        erroSefaz: 0
      },

      picked: 2,
      MONEY,

      errors: [],
      sendForm: false,
      sendFormItens: false,
      viewPage: false,
      showError: false,

      clients: [],
      itemLists: [],
      showDeleteButton: false,
      NFModalGenerate: false,
      showAlert: true,
      textEnd:''
    };
  },

  methods: {
    onSelectItem(selectedItem, index) {
      this.loadItensNfGenerate(selectedItem, index);
    },

    submit() {
      if (!this.sendForm) {
        this.sendForm = true;
        this.$store
          .dispatch('updateNfGenerate', this.formData)
          .then(r => {
            this.showError = false;
            this.sendForm = false;
            this.errors = [];
            toast.success('Cadastro efetuado com sucesso', { timeout: 1500 });
            this.$router.push({ name: 'nf-generate-edit', params: { id: r.id } });
          })
          .catch(e => {
            this.showError = true;
            this.sendForm = false;
            window.scroll(0, 0);
            this.errors = e.response.data.errors;
          });
      }
    },

    submitItens() {
      if (!this.sendFormItens) {
        const formItem = {
          id: this.id,
          nf_itens: this.formData.nf_itens
        };

        this.sendFormItens = true;
        this.$store
          .dispatch('addItensNfGenerate', formItem)
          .then(() => {
            this.sendFormItens = false;
            toast.success('Item salvo com sucesso', { timeout: 1500 });
          })
          .catch(e => {
            this.showError = true;
            this.sendFormItens = false;
            window.scroll(0, 0);
            this.errors = e.response.data.errors;
          });
      }
    },

    loadNfGenerate() {
      this.$store
        .dispatch('loadNfGenerate', this.id)
        .then(r => {
          this.formData = r;
          this.status = r.status;

          this.nf_itens = r.nf_itens.map(item => ({
            nf_item_id: [item.nf_item_id],
            amount: item.amount,
            price_un: item.price_un_un,
            price_total: item.price_un_total
          }));
        })
        .catch(() => {
          toast.error('Erro desconhecido', { timeout: 2000 });
        });
    },

    async checkACL() {
      this.$store
        .dispatch('ACLItems', 19)
        .then(r => {
          if (r.indexOf('nf-generate') !== -1) {
            this.viewPage = true;
          } else {
            this.$router.push({ name: 'home' });
          }
        })
        .catch(() => {
          this.$router.push({ name: 'home' });
        });
    },

    searchApiCEP() {
      this.resetFormAddress();
      let cep = this.formData.cep.replace(/\D/g, '');
      const formData = { cep: cep };
      this.$store.dispatch('searchApiCEP', formData).then(r => {
        this.formData.city = r.data.localidade;
        this.formData.state = r.data.uf;
        this.formData.stateView = r.data.uf;
        this.formData.district = r.data.bairro;
        this.formData.address = r.data.logradouro;
        this.formData.complement = r.data.complemento;
      });
    },

    resetFormAddress() {
      this.formData.city = '';
      this.formData.state = '';
      this.formData.stateView = '';
      this.formData.district = '';
      this.formData.address = '';
      this.formData.complement = '';
    },

    loadClients() {
      this.$store
        .dispatch('getClientListNf')
        .then(r => {
          // MAPEAR OS NOMES DOS CLIENTES PARA MOSTRAR NO SELECT
          this.clients = r.map(client => ({
            label: client.name,
            value: client.value,
            icon: client.icon
          }));
        })
        .catch(() => {
          toast.error('Erro desconhecido!', { timeout: 1500 });
        });
    },

    loadItemListSelect() {
      this.$store
        .dispatch('loadItemListSelect')
        .then(r => {
          // this.itemList = r.data;
          this.itemLists = r.map(itemList => ({
            value: itemList.value,
            label: itemList.label
          }));
        })
        .catch(() => {});
    },

    loadItensNfGenerate(itemValue, index) {
      this.$store
        .dispatch('loadItensNfGenerate', itemValue)
        .then(r => {
          // Atualiza o preço no primeiro item do array itens
          this.formData.nf_itens[index].price_un = r.data;
          this.calculateTotal(index);
          this.calculateOverallTotal(index);
        })
        .catch(() => {
          toast.error('Erro desconhecido', { timeout: 2000 });
        });
    },

    addBudget() {
      this.formData.nf_itens.push({
        nf_item_id: '',
        amount: 1,
        price_un: 0,
        price_total: 0
      });

      this.showDeleteButton = true; // Mostrar o botão de delete após adicionar um orçamento
      this.calculateOverallTotal();
    },

    removeBudget(index) {
      this.formData.nf_itens.splice(index, 1);
      if (this.nf_itens.length === 0) {
        this.showDeleteButton = false; // Esconder o botão de delete se não houver orçamentos
      }
      this.calculateOverallTotal();
    },

    calculateTotal(index) {
      const budget = this.formData.nf_itens[index];

      const price_un = budget.price_un;
      if (parseFloat(price_un) > 0) {
        const quantity = parseFloat(budget.amount);
        const price_unCalc = price_un.replace(/[^0-9]/g, '');
        const price_total = (quantity * price_unCalc) / 100;
        budget.price_total = price_total.toFixed(2).replace('.', ',');
      } else {
        budget.price_total;
      }
    },

    calculateOverallTotal() {
      let total = 0;
      const itens = this.formData.nf_itens;
      itens.forEach(item => {
        let sum = String(item.price_total).replace(/[^0-9]/g, '');
        total += parseFloat(sum);
      });

      const result = total / 100;
      return result.toLocaleString('pt-br', { minimumFractionDigits: 2 });
    },

    statusString(value) {
      if (parseInt(value) === 1) return 'Em Cadastro';
      if (parseInt(value) === 2) return 'Emitida';
      return 'Cancelada';
    },

    switchToTab(tabNumber) {
      this.picked = tabNumber;
      // Sincroniza o estado dos radio buttons com o valor de picked
      this.$nextTick(() => {
        document.getElementById(`tab-${tabNumber}`).checked = true;
      });
    },

    destroyGenerateNF(id) {
      swal({
        title: 'Atenção!',
        text: `Deseja realmente cancelar a NF?`,
        closeOnClickOutside: false,
        buttons: {
          cancel: {
            text: 'Não',
            visible: true,
            closeModal: true
          },
          confirm: 'Sim'
        }
      }).then(result => {
        if (result) {
          this.isLoading = true;
          this.$store
            .dispatch('cancelNfGenerate', id)
            .then(() => {
              toast.success('Cancelado com sucesso', { timeout: 2000 });
              this.formData.status = 4;
            })
            .catch(() => {});
        }
      });
    },

    closeNFModalGenerate() {
      this.NFModalGenerate = false;

    },

    closeNFModalGenerateDow() {
      this.NFModalGenerate = false;
    },

    generateNF() {
      this.NFModalGenerate = true;
      this.$store
        .dispatch('addGenerateNF', this.id)
        .then(() => {
            setTimeout(()=>{
              this.getGenerateNf();
            },35000)

        })
        .catch(err => {
          this.showError = true;
          this.sendForm = false;
          window.scroll(0, 0);
          this.errors = err.response.data.errors;
        });
    },

    getGenerateNf() {
      this.$store
        .dispatch('getGenerateNf', this.id)
        .then(r => {
          this.formData.linkPDF = r.linkPDF;
          this.formData.linkXML = r.linkXML;
          this.formData.status =3
          this.textEnd='Nota fiscal emitida'

        })
        .catch(err => {
          this.NFModalGenerate=false
          this.showError = true;
          this.sendForm = false;
          window.scroll(0, 0);
          this.errors = err.response.data.errors;
          this.desterrors = err.response.data.errors;
          this.formData.status =2
          this.textEnd='Erro na emissão da NF'
        });
    },

    loadStoreClientAdress(clientId) {
      this.$store
        .dispatch('storeClientAdress', { id: clientId })
        .then(r => {
          const address = r.data || r;
          this.formData.cep = address.cep || '';
          this.formData.city = address.city || '';
          this.formData.state = address.state || '';
          this.formData.district = address.district || '';
          this.formData.address = address.address || '';
          this.formData.complement = address.complement || '';
          this.formData.number = address.number || '';
        })

        .catch(() => {});
    },

    openLink(type) {
      if (type == 1) window.open(this.formData.linkPDF, '_blank').focus();
      if (type == 2) window.open(this.formData.linkXML, '_blank').focus();
    },

    closeAlert() {
      this.formData.erroSefaz = 0; // ou outro valor para ocultar o alerta
    }
  },

  computed: {
    isDisabled() {
      const disabled = this.formData.status > 2;
      return disabled;
    },
    selectStatusGenerateNF() {
      return this.$store.state.var.selectStatusGenerateNF;
    },

    me() {
      return this.$store.state.auth.me;
    },

    selectNaturezaOperacao() {
      return this.$store.state.var.selectNaturezaOperacao;
    },

    selectTypeDoc() {
      return this.$store.state.var.selectTypeDoc;
    },

    tabMobile() {
      return parseInt(this.picked) !== 2;
    }
  }
};
</script>

<style scoped>
.modal-w {
  place-items: center;
  background: rgba(0, 0, 0, 0.9);
  z-index: 99;
}

.modal-p {
  width: 60%;
  overflow-y: auto;
  min-height: 550px;
  background: #ffffff;
}

.btn-close {
  top: 10px;
  right: 10px;
  position: absolute;
}

.painel {
  padding: 1rem;
  background: #ffffff;
}

.tabs > input,
.tabs > span,
.effect-3 .line {
  width: 50%;
}

.font-span {
  font-size: 14px;
}

#tab-2,
#tab-2 + span,
#tab-2:checked ~ .line {
  left: 50%;
}

.total_price_un {
  text-align: right;
  font-size: 1.1rem;
  font-weight: 600;
  background-color: #e9ecef;
  padding: 5px;
}

.acountin {
  width: 20px;
  height: 25px;
  display: flex;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  border-radius: 50%;
  background: #000;
  align-items: center;
  justify-content: center;
}

.back-to-info {
  background-color: #6c757d;
  border: none;
}

/* .btn-submit[data-v-2c6d11e8]:hover {
    color: #ffffff;
    background-color: #4f565c !important;
} */
</style>
