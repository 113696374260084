<template>
  <painel-component :items="itemsOne">
    <error-component :errors="errors" v-if="showError" />

    <section>
      <form>
        <row-component>
          <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-9 col-sm-12 col-12">
            <label>Cliente:</label>
            <MultiSelect
              v-model="formData.client_id"
              :options="clients"
              @select="loadStoreClientAdress(formData.client_id)"
            />
          </col-component>

          <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <label>Natureza operação:</label>
            <MultiSelect v-model="formData.nature_operation" :options="selectNaturezaOperacao" />
          </col-component>
        </row-component>

        <row-component>
          <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
            <label>CEP:</label>
            <input-component v-model="formData.cep" v-mask="'#####-###'" @change="searchApiCEP" />
          </col-component>

          <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
            <label>Cidade:</label>
            <input-component v-model="formData.city" />
          </col-component>

          <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
            <label>Estado:</label>
            <input-component v-model="formData.state" />
          </col-component>

          <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
            <label>Bairro:</label>
            <input-component v-model="formData.district" />
          </col-component>
        </row-component>

        <row-component>
          <col-component class-col="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12">
            <label>Endereço:</label>
            <input-component v-model="formData.address" />
          </col-component>

          <col-component class-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
            <label>Nº:</label>
            <input-component v-model="formData.number" />
          </col-component>
        </row-component>

        <row-component>
          <col-component
            class-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
            :class="{ formError: errors.desc }"
          >
            <label>Informações adicionais:</label>
            <text-area v-model="formData.desc" rows="4" />
          </col-component>
        </row-component>

        <row-component>
          <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <button-submit :disabled="sendForm" @click="submit" class="w-100" icon="plus" name="Adicionar" />
          </col-component>

          <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <button-route class="w-100" :items="itemsTwo" />
          </col-component>
        </row-component>
      </form>
    </section>
  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import ColComponent from '@/components/row/ColComponent';
import MultiSelect from '@/components/form/MultiSelect';
import InputComponent from '@/components/form/InputComponent';
import ErrorComponent from '@/components/alerts/ErrorComponent';
import ButtonSubmit from '@/components/buttons/ButtonSubmit';
import ButtonRoute from '@/components/buttons/ButtonRoute';
import TextArea from '@/components/form/TextArea';

import { VMoney } from 'v-money';
import { mask } from 'vue-the-mask';
import { MONEY } from '@/configs/constants';

import { useToast } from 'vue-toastification';

const toast = useToast();
export default {
  name: 'GenerateNFCreate',
  components: {
    ErrorComponent,
    InputComponent,
    MultiSelect,
    ColComponent,
    RowComponent,
    PainelComponent,
    ButtonRoute,
    ButtonSubmit,
    TextArea
  },

  directives: {
    mask,
    money: VMoney
  },

  created() {
    this.$store.dispatch('changeNamePage', 'Adicionar NF');
    this.checkACL();
    this.loadClients();
    // this.loadStoreClientAdress();
  },

  data() {
    return {
      itemsOne: {
        title: 'Adicionar',
        icon: 'pen square'
      },

      itemsTwo: {
        route: '/nf-generate/search',
        icon: 'reply all',
        name: 'Voltar'
      },

      formData: {
        client_id: '',
        cep: '',
        city: '',
        state: '',
        district: '',
        address: '',
        complement: '',
        nature_operation: '',
        number: '',
        desc: ''
      },

      picked: 2,

      MONEY,

      errors: [],
      sendForm: false,
      viewPage: false,
      showError: false,

      clients: []
    };
  },

  methods: {
    handleClientChange(clientId) {
      if (clientId) {
        this.loadStoreClientAdress(clientId);
      }
    },

    submit() {
      if (!this.sendForm) {
        this.sendForm = true;
        this.$store
          .dispatch('storeNfGenerate', this.formData)
          .then(r => {
            this.showError = false;
            this.sendForm = false;
            this.errors = [];
            toast.success('Cadastro efetuado com sucesso', { timeout: 1500 });
            this.$router.push({ name: 'nf-generate-edit', params: { id: r } });
          })
          .catch(e => {
            this.showError = true;
            this.sendForm = false;
            window.scroll(0, 0);
            this.errors = e.response.data.errors;
          });
      }
    },

    async checkACL() {
      this.$store
        .dispatch('ACLItems', 19)
        .then(r => {
          if (r.indexOf('nf-generate') !== -1) {
            this.viewPage = true;
          } else {
            this.$router.push({ name: 'home' });
          }
        })
        .catch(() => {
          this.$router.push({ name: 'home' });
        });
    },

    searchApiCEP() {
      this.resetFormAddress();
      let cep = this.formData.cep.replace(/\D/g, '');
      const formData = { cep: cep };
      this.$store.dispatch('searchApiCEP', formData).then(r => {
        this.formData.city = r.data.localidade;
        this.formData.state = r.data.uf;
        this.formData.stateView = r.data.uf;
        this.formData.district = r.data.bairro;
        this.formData.address = r.data.logradouro;
        this.formData.complement = r.data.complemento;
      });
    },

    resetFormAddress() {
      this.formData.city = '';
      this.formData.state = '';
      this.formData.stateView = '';
      this.formData.district = '';
      this.formData.address = '';
      this.formData.complement = '';
    },

    resetFormData() {
      this.formData = {
        client_id: '',
        cep: '',
        city: '',
        state: '',
        district: '',
        address: '',
        complement: ''
      };
    },

    completeInput(data) {
      this.formData.cep = data.address.zip;
      this.formData.city = data.address.city;
      this.formData.stateView = data.address.state;
      this.formData.state = data.address.state;
      this.formData.district = data.address.district;
      this.formData.address = `${data.address.street}, ${data.address.number}`;
      this.formData.complement = data.address.details;
      this.formData.size = `${data.company.size.id} -  ${data.company.size.acronym} - ${data.company.size.text}`;
    },

    loadClients() {
      this.$store
        .dispatch('loadClientList')
        .then(r => {
          // MAPEAR OS NOMES DOS CLIENTES PARA MOSTRAR NO SELECT
          this.clients = r.map(client => ({
            label: client.name,
            value: client.value,
            icon: client.icon
          }));
        })
        .catch(() => {
          toast.error('Erro desconhecido!', { timeout: 1500 });
        });
    },

    loadStoreClientAdress(clientId) {
      this.$store
        .dispatch('storeClientAdress', { id: clientId })
        .then(r => {
          const address = r.data || r;
          this.formData.cep = address.cep || '';
          this.formData.city = address.city || '';
          this.formData.state = address.state || '';
          this.formData.district = address.district || '';
          this.formData.address = address.address || '';
          this.formData.complement = address.complement || '';
          this.formData.number = address.number || '';
        })

        .catch(() => {});
    }
  },
  computed: {
    me() {
      return this.$store.state.auth.me;
    },

    selectNaturezaOperacao() {
      return this.$store.state.var.selectNaturezaOperacao;
    },

    tabMobile() {
      return parseInt(this.picked) !== 2;
    }
  }
};
</script>
