import menuAdmin from '@/configs/links-menu/modules/menuAdmin';
import menuEcommerce from '@/configs/links-menu/modules/menuEcommerce';
import menuPDV from '@/configs/links-menu/modules/menuPDV';
import menuStock from '@/configs/links-menu/modules/menuStock';
import menuFinanceiro from '@/configs/links-menu/modules/menuFinanceiro';
import menuRelatorio from '@/configs/links-menu/modules/menuRelatorio';
import menuConsignado from '@/configs/links-menu/modules/menuConsignado';

export default {
  menuAdmin,
  menuEcommerce,
  menuPDV,
  menuStock,
  menuFinanceiro,
  menuRelatorio,
  menuConsignado
};
