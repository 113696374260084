<template>
  <painel-component :items="itemsOne">
    <form>
      <row-component>
        <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
          <label>Cliente:</label>
          <input-component @input="filterData" v-model="formData.cliente" placeholder="Cliente" />
        </col-component>

        <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
          <label>Status:</label>
          <MultiSelect v-model="formData.status" :options="selectEcommerceSales" placeholder="Status" />
        </col-component>

        <col-component class-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
          <label>Data Inicio:</label>
          <input-component v-mask="'##/##/####'" v-model="formData.data_inicial" placeholder="Data Inicio" />
        </col-component>

        <col-component class-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
          <label>Data Final:</label>
          <input-component v-mask="'##/##/####'" v-model="formData.data_final" placeholder="Data Inicio" />
        </col-component>

        <col-component class-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
          <label>Página:</label>
          <MultiSelect v-model="formData.pagina" :options="selectPage" placeholder="Página" />
        </col-component>
      </row-component>

      <row-component>
        <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
          <button-submit @click="submit" icon="search" name="Pesquisar" />
        </col-component>
      </row-component>
    </form>

    <div class="bg-body-secondaryd">
      <div class="bg-white">
        <div class="__table">
          <table class="table table-striped" v-if="viewPage">
            <thead>
              <tr>
                <th scope="col" style="width: 2%">Número</th>
                <th scope="col" style="width: 7%">Código rastreamento</th>
                <th scope="col" style="width: 10%">Data/Hora</th>
                <th scope="col" style="width: 15%">Cliente</th>
                <th scope="col" style="width: 10%">Forma Pgto.</th>
                <th scope="col" style="width: 10%">Frete</th>
                <th scope="col" style="width: 5%">Valor</th>
                <th scope="col" style="width: 5%">Status</th>
                <th scope="col" style="width: 5%">Peso</th>
                <th scope="col" style="width: 5%">Ações</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(data, index) in object.registros" :key="index">
                <td data-label="Número">{{ data.id }}</td>
                <td data-label="Código rastreamento">{{ data.n_envio ? data.n_envio : 'N/D' }}</td>
                <td data-label="Data/Hora">{{ formatDate(data.data) }}</td>
                <td data-label="Cliente">{{ data.nome_cliente }}</td>
                <td data-label="Forma Pgto.">{{ data.forma_pgto }}</td>
                <td data-label="Frete">
                  {{ data.frete_tipo }} <br />
                  R$ {{ formatMoney(data.frete) }}
                </td>
                <td data-label="Valor">R$ {{ formatMoney(data.vlr_total_pedido) }}</td>
                <td data-label="Status">
                  <span
                    class="tag"
                    :class="{ 'text-white': colorChange(data.status.id) }"
                    :style="{ backgroundColor: data.status.cor_html }"
                  >
                    {{ data.status.descricao }}
                  </span>
                </td>
                <td data-label="Peso">{{ data.peso_total_pedido }}</td>
                <td data-label="Ações">
                  <button-show :id="data.id" route="commerce-sales-show" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import ColComponent from '@/components/row/ColComponent';
import InputComponent from '@/components/form/InputComponent';
import MultiSelect from '@/components/form/MultiSelect';
import ButtonSubmit from '@/components/buttons/ButtonSubmit';
import ButtonShow from '@/components/buttons/ButtonShow';

import { mask } from 'vue-the-mask';
import { useToast } from 'vue-toastification';

const toast = useToast();
export default {
  name: 'CommerceSalesSearch',
  components: {
    ButtonShow,
    ButtonSubmit,
    MultiSelect,
    InputComponent,
    ColComponent,
    RowComponent,
    PainelComponent
  },

  directives: {
    mask
  },

  created() {
    this.$store.dispatch('changeNamePage', 'Ecommerce Vendas');
    this.loadItems(1);
    this.checkACL();
  },

  data() {
    return {
      itemsOne: {
        icon: 'list alternate',
        title: 'Pesquisar'
      },

      formData: {
        pagina: '',
        cliente: '',
        status: '',
        data_inicial: this.initialDate(),
        data_final: this.dateCurrent()
      },

      viewPage: false,
      totalPage: ''
    };
  },

  methods: {
    submit() {
      this.loadItems(1);
      this.searchSubmit = true;
    },

    loadItems(page) {
      if (!this.searchSubmit) {
        this.$store
          .dispatch('loadCommerceSales', { ...this.params, page })
          .then(r => {
            if (this.searchSubmit) {
              this.searchSubmit = false;
              if (r.registros.length === 0) {
                toast.info('Nada foi encontrado com os parâmetros informados!', { timeout: 2000 });
              }
            }
            this.sortArr();
            this.totalPage = r.totalPaginas;
            this.pagesSelect();
          })
          .catch(() => {
            this.searchSubmit = false;
          });
      }
    },

    async checkACL() {
      this.$store
        .dispatch('ACLItems', 16)
        .then(r => {
          if (r.indexOf('bw-sales') !== -1) {
            this.viewPage = true;
          } else {
            this.$router.push({ name: 'home' });
          }
        })
        .catch(() => {
          this.$router.push({ name: 'home' });
        });
    },

    pagesSelect() {
      const pageArr = [];
      for (let i = 0; i < this.totalPage; i++) {
        pageArr.push({
          label: `Página ${i + 1}`,
          value: i + 1
        });
      }

      return pageArr;
    },

    initialDate() {
      let currentTime = new Date();

      let day = new Date(currentTime.getFullYear(), currentTime.getMonth(), 1);
      let month = currentTime.getMonth() + 1;
      let year = currentTime.getFullYear();

      // pad numbers with zero if necessary
      day = ('0' + day.getDate()).slice(-2);
      month = (month < 10 ? '0' : '') + month;

      return `${day}/${month}/${year}`;
    },

    dateCurrent() {
      let currentTime = new Date();

      let day = currentTime.getDate();
      let month = currentTime.getMonth() + 1;
      let year = currentTime.getFullYear();

      // pad numbers with zero if necessary
      day = (day < 10 ? '0' : '') + day;
      month = (month < 10 ? '0' : '') + month;

      return `${day}/${month}/${year}`;
    },

    formatDate(value) {
      let date = value.split(' ')[0];
      let hour = value.split(' ')[1];
      date = date.split('-');
      return `${date[2]}/${date[1]}/${date[0]} - ${hour}`;
    },

    formatMoney(value) {
      return value.toLocaleString('pt-br', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    },

    colorChange(value) {
      value = parseInt(value);
      return value >= 2 || value < 6 || value !== 4 || value === 9;
    },

    filterData() {
      if (this.formData.cliente === '') this.loadItems(1);
      else
        this.object.registros = this.object.registros.filter(obj =>
          obj.nome_cliente.toLowerCase().includes(this.formData.cliente.toLowerCase())
        );
    },

    sortArr() {
      return (this.object.registros = this.object.registros.sort((a, b) => b.id - a.id));
    }
  },

  computed: {
    selectEcommerceSales() {
      return this.$store.state.var.selectEcommerceSales;
    },

    selectPage() {
      return this.pagesSelect();
    },

    object() {
      return this.$store.state.e_commerce_sales.items;
    },

    params() {
      return {
        pagina: this.formData.pagina,
        status: this.formData.status,
        data_inicial: this.formData.data_inicial,
        data_final: this.formData.data_final
      };
    }
  }
};
</script>

<style scoped>
span.tag {
  color: #000;
  padding: 0.3rem;
}
</style>
