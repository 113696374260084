<template>
  <painel-component :items="itenstwo">
    <row-component class="justify-content-end">
      <col-component :class-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
        <label>Selecionar ano:</label>
        <Multiselect v-model="currentYear" :options="selectYear" @select="multiLoad(currentYear)" />
      </col-component>
    </row-component>
    <BarChart :height="barChartMobile" :chartData="chartDataPreview"> </BarChart>
  </painel-component>

  <painel-component :items="itensOne">
    <BarChart :width="barChartMobile" :height="barChartHeight" :chartData="chartDataEffective">
    </BarChart>
  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import ColComponent from '@/components/row/ColComponent';
import Multiselect from '@/components/form/MultiSelect';
import BarChart from '@/components/chart/BarChart';

import { useToast } from 'vue-toastification';

const toast = useToast();

export default {
  name: 'HomeView',

  components: {
    PainelComponent,
    RowComponent,
    ColComponent,
    Multiselect,
    BarChart
  },

  created() {
    this.checkACL();
    this.loadHome(this.currentYear);
    this.dynamicTitle();
    this.$store.dispatch('changeNamePage', 'Home');
  },

  data() {
    return {
      barChartMobile: 100,

      itensOne: {
        title: '',
        icon: 'chart bar outlin'
      },
      itenstwo: {
        title: '',
        icon: 'chart bar outline'
      },

      showListHome: false,
      showBarHome: false,

      yearSelect: [],
      currentYear: this.getYear(),

      chartDataPreview: {
        labels: [],
        datasets: []
      },

      chartDataEffective: {
        labels: [],
        datasets: []
      },

      chartEntrancePreview: {
        label: 'Entrada',
        data: [],
        backgroundColor: ['rgba(22, 171, 57, 0.3)'],
        borderColor: ['rgba(22, 171, 57)'],
        borderWidth: 1
      },

      chartExitPreview: {
        label: 'Saída',
        data: [],
        backgroundColor: ['rgba(219, 40, 40, 0.3)'],
        borderColor: ['rgba(219, 40, 40)'],
        borderWidth: 1
      },

      chartEntranceEffective: {
        label: 'Entrada',
        data: [],
        backgroundColor: ['rgba(22, 171, 57, 0.3)'],
        borderColor: ['rgba(22, 171, 57)'],
        borderWidth: 1
      },

      chartExitEffective: {
        label: 'Saída',
        data: [],
        backgroundColor: ['rgba(219, 40, 40, 0.3)'],
        borderColor: ['rgba(219, 40, 40)'],
        borderWidth: 1
      },

      barChartWidth: 300,
      barChartHeight: 30
    };
  },

  methods: {
    multiLoad(year) {
      this.loadHome(year);
    },

    loadHome(year) {
      this.$store
        .dispatch('loadHome', year)
        .then(r => {
          let preview = r.cashFlowForecast;

          // Atualização dos gráficos.
          // (chartDataPreview e chartDataEffective),

          this.chartDataPreview = {
            labels: preview.labels,
            datasets: [
              {
                ...this.chartEntrancePreview,
                data: preview.entrance
              },
              {
                ...this.chartExitPreview,
                data: preview.exit
              }
            ]
          };

          let effective = r.cashFlowEffective;

          this.chartDataEffective = {
            labels: effective.labels,
            datasets: [
              {
                ...this.chartEntranceEffective,
                data: effective.entrance
              },
              {
                ...this.chartExitEffective,
                data: effective.exit
              }
            ]
          };
          // as opções de ano (yearSelect)
          this.yearSelect = r.yearSelect;
          // e o título da página (dynamicTitle)
          this.dynamicTitle();
        })
        .catch(() => {
          toast.error('Erro desconhecido', { timeout: 1200 });
        });
    },

    dynamicTitle() {
      let year = this.currentYear;
      this.itensOne.title = `Fluxo de caixa ${year} (Efetivado)`;
      this.itenstwo.title = `Fluxo de caixa ${year} (Previsão)`;
    },

    getYear() {
      let date = new Date();
      return date.getFullYear();
    },

    async checkACL() {
      this.$store.dispatch('ACLItems', 20).then(r => {
        if (r.indexOf('graphic-home') !== -1) {
          this.viewPage = true;
        }
      });
    },

    checkPermission(page) {
      return this.permissionsPage.indexOf(page) !== -1;
    }
  },

  computed: {
    selectYear() {
      return this.yearSelect;
    }
  },

  beforeUpdate() {
    if (window.innerWidth <= 991) {
      this.barChartWidth = 250;
      this.barChartHeight = 250;
    } else {
      this.barChartWidth = 300;
      this.barChartHeight = 300;
    }
  }
};
</script>
