<template>
  <div
    class="modal-w d-grid position-fixed top-0 start-0 h-100 w-100"
    v-if="modal_update || modal_low"
  >
    <entrance-and-exit-preview-update-modal
      v-if="modal_update"
      :id="this.id_modal"
      class="modal-entrance-exit-edit"
      @close="closeModalUpdate"
    />

    <entrance-and-exit-preview-low-modal
      v-if="modal_low"
      :id="this.id_modal"
      class="modal-entrance-exit-edit"
      @close="closeModalLow"
    />
  </div>

  <painel-component :items="itemsOne">
    <row-component>
      <col-component
        :class-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
      >
        <label>Código:</label>
        <input-component :type-input="'text'" v-model="formData.code" />
      </col-component>

      <col-component
        :class-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
      >
        <label>Mês:</label>
        <MultiSelect
          v-model="formData.month"
          :options="selectMonthList"
          :searchable="true"
          :noOptionsText="noOptionsText"
          :noResultsText="noResultsText"
        />
      </col-component>

      <col-component
        :class-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
      >
        <label>Ano:</label>
        <MultiSelect
          v-model="formData.year"
          :options="getYearSelect()"
          :searchable="true"
          :noOptionsText="noOptionsText"
          :noResultsText="noResultsText"
        />
      </col-component>
    </row-component>

    <row-component>
      <col-component
        :class-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
      >
        <label>Conta Bancária:</label>
        <MultiSelect
          label="name"
          v-model="formData.bank_accounts_id"
          :options="selectAccountBankList"
          :searchable="true"
          :noOptionsText="noOptionsText"
          :noResultsText="noResultsText"
        />
      </col-component>
      <col-component
        :class-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
      >
        <label>Tipo Entrada/Saída:</label>
        <MultiSelect
          v-model="formData.type"
          :options="selectEntranceExitList"
          :searchable="true"
          :noOptionsText="noOptionsText"
          :noResultsText="noResultsText"
        />
      </col-component>

      <col-component
        class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
      >
        <label>Tipo de conta:</label>
        <MultiSelect
          v-model="formData.account_types_id"
          :searchable="true"
          @select="selectSubTypeAccount(formData.account_types_id)"
          :options="selectAccountType"
          :noResultsText="noResultsText"
          :noOptionsText="noResultsText"
        />
      </col-component>

      <col-component
        class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
      >
        <label>Tipo de subconta:</label>
        <MultiSelect
          v-model="formData.account_type_sub_id"
          :searchable="true"
          :options="selectAccountTypeSubList"
          :noResultsText="noResultsText"
          :noOptionsText="noResultsText"
        />
      </col-component>
    </row-component>

    <row-component>
      <col-component
        :class-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mt-4'"
      >
        <button-submit
          class="me-1"
          @click="submit"
          icon="search"
          name="Pesquisar"
        />
        <button-route v-if="buttonCheck.create" :items="itemsTwo" />
      </col-component>
    </row-component>

    <row-component>
      <col-component
        :class-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
      >
        <div
          class="bg-card-alert alert alert-secondary alert-dismissible fade show"
          role="alert"
        >
          <p>
            Total de entrada: {{ entrance_exit_preview.totalQuantEntrance }}
          </p>
          <p>
            Valor: <span>R${{ entrance_exit_preview.totalValueEntrance }}</span>
          </p>
          <i
            class="icon-card-entrance arrow alternate circle up outline icon"
          ></i>
        </div>
      </col-component>

      <col-component
        :class-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
      >
        <div
          class="bg-card-alert alert alert-secondary alert-dismissible fade show"
          role="alert"
        >
          <p>Total de saída: {{ entrance_exit_preview.totalQuantExit }}</p>
          <p>
            Valor: <span>R${{ entrance_exit_preview.totalValueExit }}</span>
          </p>
          <i
            class="icon-card-exit arrow alternate circle down outline icon"
          ></i>
        </div>
      </col-component>
      <col-component
        :class-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
      >
        <div
          class="bg-card-alert alert alert-secondary alert-dismissible fade show"
          role="alert"
        >
          <p>Subtotal</p>
          <p>
            Valor: <span>R${{ entrance_exit_preview.subtotal }}</span>
          </p>
          <i class="icon-card-entrance money outline icon"></i>
        </div>
      </col-component>
    </row-component>

    <div class="bg-body-secondaryd">
      <div class="bg-white">
        <div class="__table">
          <table class="table table-striped mt-4">
            <thead>
              <tr>
                <th scope="col" style="width: 5%">Código</th>
                <th scope="col" style="width: 10%">Conta Bancária</th>
                <th scope="col" style="width: 10%">
                  Tipo de Conta <br />
                  Tipo de Sub Conta
                </th>
                <th scope="col" style="width: 5%">Preço</th>
                <th scope="col" style="width: 5%">Entrada/Saída</th>
                <th scope="col" style="width: 10%">Data de Vencimento</th>
                <th
                  scope="col"
                  style="width: 10%"
                  v-if="
                    buttonCheck.delete || buttonCheck.edit || buttonCheck.low
                  "
                >
                  Ações
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(entrance_exit_preview, index) in this.object"
                :key="index"
              >
                <td data-label="Código">{{ entrance_exit_preview.code }}</td>
                <td data-label="Conta Bancária">
                  <img
                    v-if="entrance_exit_preview.bank_accounts_photo"
                    class="avatar__image mx-1"
                    :src="entrance_exit_preview.bank_accounts_photo"
                    alt=""
                  />
                  <img
                    v-else
                    class="avatar__image mx-1"
                    src="@/assets/avatar.png"
                    alt=""
                  />
                  {{ entrance_exit_preview.bank_accounts_name }}
                </td>
                <td data-label="Tipo de Conta/Tipo de Sub Conta">
                  {{ entrance_exit_preview.account_types_name }} <br />
                  {{ entrance_exit_preview.account_types_sub_name }}
                </td>
                <td data-label="Preço">{{ entrance_exit_preview.price }}</td>
                <td data-label="Entrada/Saída">
                  {{ statusString(entrance_exit_preview.type) }}
                </td>
                <td data-label="Data de Vencimento">
                  {{ entrance_exit_preview.due_date }}
                </td>
                <td
                  data-label="Ações"
                  v-if="
                    buttonCheck.delete || buttonCheck.edit || buttonCheck.low
                  "
                >
                  <button-edit-submit
                    v-if="buttonCheck.edit"
                    @click="modalUpdate(entrance_exit_preview.id)"
                  />

                  <button-delete
                    v-if="buttonCheck.delete"
                    @click="destroyEntranceExitPreview(entrance_exit_preview)"
                  />

                  <button
                    v-if="buttonCheck.low"
                    class="btn btn-submit rounded-0 m-1"
                    @click="modalLow(entrance_exit_preview.id)"
                  >
                    <i
                      :class="[
                        {
                          'arrow alternate circle up outline':
                            parseInt(entrance_exit_preview.type) === 1,
                          'arrow alternate circle down outline':
                            parseInt(entrance_exit_preview.type) === 2
                        },
                        'icon'
                      ]"
                    ></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <button-pagination
      :pagination="entrance_exit_preview.itens"
      :offset="3"
      @paginate="loadEntriesDeparturesPreview"
    />
  </painel-component>
</template>

<style scoped>
.avatar__image {
  width: 30px;
}

.bg-card-alert p {
  margin: 0;
}

.bg-card-alert span {
  font-weight: bold;
}

.card-container h3 {
  color: #424242;
  font-size: 14px;
  font-family: inherit;
  text-transform: uppercase;
}

.icon-card-entrance,
.icon-card-entrance-table {
  font-size: 24px;
  color: #16ab39;
}

.icon-card-exit,
.icon-card-exit-table {
  font-size: 24px;
  color: #db2828;
}

.icon-card-entrance,
.icon-card-exit {
  top: 0;
  right: 0;
  font-size: 2rem;
  margin: 15px 15px;
  position: absolute;
}

.btn-submit {
  color: #ffffff;
  border-color: #000000;
  background-color: #000000;
}

.btn-submit.disabled,
.btn-submit:disabled {
  opacity: 0.8;
  color: #ffffff;
  border-color: #000000 !important;
  background-color: #000000 !important;
}

.btn-submit:active,
.btn-submit:focus,
.btn-submit:hover {
  color: #ffffff;
  border-color: #000000 !important;
  background-color: #000000 !important;
}

.modal-w {
  place-items: center;
  background: rgba(0, 0, 0, 0.9);
  z-index: 99;
}
</style>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent.vue';
import RowComponent from '@/components/row/RowComponent.vue';
import ColComponent from '@/components/row/ColComponent.vue';
import InputComponent from '@/components/form/InputComponent.vue';
import MultiSelect from '@/components/form/MultiSelect.vue';
import { useToast } from 'vue-toastification';

const toast = useToast();
import swal from 'sweetalert';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import ButtonRoute from '@/components/buttons/ButtonRoute.vue';
import ButtonPagination from '@/components/buttons/ButtonPagination.vue';
import ButtonDelete from '@/components/buttons/ButtonDelete.vue';
import ButtonEditSubmit from '@/components/buttons/ButtonEditSubmit.vue';
import EntranceAndExitPreviewUpdateModal from '@/components/modal/EntranceAndExitPreviewUpdateModal.vue';
import EntranceAndExitPreviewLowModal from '@/components/modal/EntranceAndExitPreviewLowModal.vue';

export default {
  name: 'EntranceExitPreviewSearch',
  components: {
    EntranceAndExitPreviewLowModal,
    EntranceAndExitPreviewUpdateModal,
    ButtonEditSubmit,
    ButtonDelete,
    ButtonPagination,
    ButtonRoute,
    ButtonSubmit,
    InputComponent,
    ColComponent,
    RowComponent,
    PainelComponent,
    MultiSelect
  },

  created() {
    this.$store.dispatch('changeNamePage', 'Entrada/Saída (Previsão)');
    this.$store.dispatch('loadAccountBankMultiSelect');
    this.$store.dispatch('loadAccountTypeMultiSelect');
    this.checkACL();
    this.loadEntriesDeparturesPreview(1);
  },

  data() {
    return {
      itemsOne: {
        title: 'Pesquisar',
        icon: 'list alternate'
      },

      itemsTwo: {
        route: '/entrance-exit-preview/create',
        icon: 'plus',
        name: 'Adicionar'
      },

      buttonSubmit: {
        icon: 'ti-search'
      },

      buttonRoute: {
        icon: 'ti-plus',
        route: '/entrance-exit-preview/create'
      },

      buttonCheck: {
        create: false,
        delete: false,
        edit: false,
        low: false
      },

      object: {},

      modal_low: false,
      modal_update: false,
      id_modal: '',

      formData: {
        month: this.getMonth(),
        year: this.getYear(),
        code: '',
        bank_accounts_id: '',
        account_types_id: '',
        account_type_sub_id: '',
        type: ''
      },

      searchSubmit: false
    };
  },

  methods: {
    submit() {
      this.loadEntriesDeparturesPreview(1);
      this.searchSubmit = true;
    },

    modalUpdate(id) {
      this.modal_update = true;
      this.id_modal = id;
    },

    modalLow(id) {
      this.modal_low = true;
      this.id_modal = id;
    },

    statusString(value) {
      if (value === '1') return 'Entrada';
      return 'Saída';
    },

    getYearSelect() {
      let dataActual = new Date();
      let year = dataActual.getFullYear() + 2;
      let years = [];
      for (let i = 2021; i <= year; i++) {
        years.push(i);
      }
      return years;
    },

    getYear() {
      let date = new Date();
      return date.getFullYear();
    },

    getMonth() {
      let date = new Date();
      return date.getMonth() + 1;
    },

    loadEntriesDeparturesPreview(page) {
      if (!this.searchSubmit) {
        this.$store
          .dispatch('loadEntriesDeparturesPreview', { ...this.params, page })
          .then(r => {
            this.object = r.itens.data;
            if (this.searchSubmit) {
              this.searchSubmit = false;
              if (r.itens.data == '') {
                toast.info(
                  'Nada foi encontrado com os parâmetros informados!',
                  { timeout: 2000 }
                );
              }
            }
          })
          .catch(() => {
            this.searchSubmit = false;
          });
      }
    },

    async checkACL() {
      this.$store
        .dispatch('ACLItems', 13)
        .then(r => {
          if (r.indexOf('entrance-exit-preview-search') !== -1) {
            if (r.indexOf('entrance-exit-preview-create') !== -1) {
              this.buttonCheck.create = true;
            }

            if (r.indexOf('entrance-exit-preview-edit') !== -1) {
              this.buttonCheck.edit = true;
            }

            if (r.indexOf('entrance-exit-preview-low') !== -1) {
              this.buttonCheck.low = true;
            }

            if (r.indexOf('entrance-exit-preview-delete') !== -1) {
              this.buttonCheck.delete = true;
            }
          } else {
            this.$router.push({ name: 'home' });
          }
        })
        .catch(() => {
          this.$router.push({ name: 'home' });
        });
    },

    destroyEntranceExitPreview(item) {
      swal({
        title: 'Atenção!',
        text: `Deseja realmente deletar ${item.name}, ${this.me.name} ?`,
        closeOnClickOutside: false,
        buttons: {
          cancel: {
            text: 'Não',
            visible: true,
            closeModal: true
          },
          confirm: 'Sim'
        }
      }).then(result => {
        if (result) {
          this.$store
            .dispatch('destroyEntranceExitPreview', item.id)
            .then(() => {
              this.loadEntriesDeparturesPreview(1);
              toast.success('Cadastro deletado com sucesso', { timeout: 2000 });
            })
            .catch(() => {
              toast.error(`Não foi possivel deletar  ${item.name} `, {
                timeout: 2000
              });
            });
        }
      });
    },

    closeModalUpdate() {
      this.modal_update = false;
      this.loadEntriesDeparturesPreview(1);
    },

    closeModalLow() {
      this.modal_low = false;
      this.loadEntriesDeparturesPreview(1);
    },

    selectSubTypeAccount(id) {
      this.formData.account_type_sub_id = '';
      this.$store.dispatch('loadAccountTypeSubMultiSelect', id);
    }
  },

  computed: {
    selectActiveInative() {
      return this.$store.state.var.selectActiveInative;
    },

    noResultsText() {
      return this.$store.state.var.noResultsText;
    },

    noOptionsText() {
      return this.$store.state.var.noOptionsText;
    },

    selectMonthList() {
      return this.$store.state.var.selectMonthList;
    },

    selectEntranceExitList() {
      return this.$store.state.var.selectEntranceExitList;
    },

    selectAccountBankList() {
      return this.$store.state.var.selectAccountBankList;
    },

    selectAccountList() {
      return this.$store.state.var.selectAccountList;
    },

    selectYesNoList() {
      return this.$store.state.var.selectYesNoList;
    },

    entrance_exit_preview() {
      return this.$store.state.entrance_exit_preview.items;
    },

    selectAccountType() {
      return this.$store.state.var.selectAccountList;
    },

    selectAccountTypeSubList() {
      return this.$store.state.var.selectAccountSubList;
    },

    params() {
      return {
        page: this.entrance_exit_preview.current_page,
        month: this.formData.month,
        year: this.formData.year,
        code: this.formData.code,
        bank_accounts_id: this.formData.bank_accounts_id,
        account_types_id: this.formData.account_types_id,
        account_type_sub_id: this.formData.account_type_sub_id,
        type: this.formData.type
      };
    },

    me() {
      return this.$store.state.auth.me;
    }
  }
};
</script>
