<template>
  <painel-component :items="itemsOne">
    <form>
      <row-component>
        <col-component
          class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
        >
          <label>Nome:</label>
          <input-component v-model="formData.name" placeholder="Nome" />
        </col-component>

        <col-component
          class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
        >
          <label>Tipo:</label>
          <MultiSelect
            v-model="formData.type"
            placeholder="Tipo"
            :options="selectEntranceExit"
          />
        </col-component>

        <col-component
          class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
        >
          <label>Loja / BW:</label>
          <MultiSelect
            v-model="formData.shop_store"
            placeholder="Loja / BW"
            :options="selectShopStore"
          />
        </col-component>
      </row-component>

      <row-component>
        <col-component
          class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
        >
          <button-submit
            @click="submit"
            class="me-1"
            icon="search"
            name="Pesquisar"
          />
          <button-route v-if="create" :items="itemsTwo" />
        </col-component>
      </row-component>
    </form>

    <div class="bg-body-secondaryd">
      <div class="bg-white">
        <div class="__table">
          <table class="table table-striped" v-if="viewPage">
            <thead>
              <tr>
                <th scope="col" style="width: 13%">Criador</th>
                <th scope="col" style="width: 12.5%">Código</th>
                <th scope="col" style="width: 12.5%">Nome</th>
                <th scope="col" style="width: 12.5%">Data / Hora</th>
                <th scope="col" style="width: 12.5%">Loja / E-commerce</th>
                <th scope="col" style="width: 12.5%">Estoque</th>
                <th scope="col" style="width: 12.5%">Entrada / Saída</th>
                <th scope="col" style="width: 12%">Preço</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(data, index) in object.data" :key="index">
                <td data-label="Criador">
                  <img
                    width="35"
                    v-if="data.photo_create"
                    class="img-fluid rounded-circle me-2"
                    :src="data.photo_create"
                    alt=""
                  />
                  <img
                    width="35"
                    v-else
                    class="img-fluid rounded-circle me-2"
                    src="@/assets/avatar.png"
                    alt=""
                  />
                  {{ data.creator_user_name }}
                </td>
                <td data-label="Código">{{ data.code }}</td>
                <td data-label="Produto">{{ data.name }}</td>
                <td data-label="Data / Hora">
                  {{ data.date_create }} / {{ data.hour_create }}
                </td>
                <td data-label="Loja / E-commerce">
                  {{ stringShop(data.shop_store) }}
                </td>
                <td data-label="Estoque">{{ data.amount }}</td>
                <td data-label="Entrada / Saída">
                  {{ stringType(data.type) }}
                </td>
                <td data-label="Preço">{{ data.price }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <button-pagination :pagination="object" :offset="3" @paginate="loadItems" />
  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import ColComponent from '@/components/row/ColComponent';
import InputComponent from '@/components/form/InputComponent';
import MultiSelect from '@/components/form/MultiSelect';
import ButtonSubmit from '@/components/buttons/ButtonSubmit';
import ButtonRoute from '@/components/buttons/ButtonRoute';
import ButtonPagination from '@/components/buttons/ButtonPagination';

import { useToast } from 'vue-toastification';

const toast = useToast();
export default {
  name: 'InventorySearch',

  components: {
    ButtonPagination,
    ButtonRoute,
    ButtonSubmit,
    MultiSelect,
    InputComponent,
    ColComponent,
    RowComponent,
    PainelComponent
  },

  created() {
    this.$store.dispatch('changeNamePage', 'Entrada / Saída');
    this.loadItems(1);
    this.checkACL();
  },

  data() {
    return {
      itemsOne: {
        title: 'Pesquisar',
        icon: 'list alternate'
      },

      itemsTwo: {
        route: '/inventory/create',
        icon: 'plus',
        name: 'Adicionar'
      },

      formData: {
        name: '',
        type: '',
        shop_store: ''
      },

      create: false,
      viewPage: false
    };
  },

  methods: {
    submit() {
      this.loadItems(1);
      this.searchSubmit = true;
    },

    loadItems(page) {
      if (!this.searchSubmit) {
        this.$store
          .dispatch('loadInventories', { ...this.params, page })
          .then(r => {
            if (this.searchSubmit) {
              this.searchSubmit = false;
              if (r.data.length === 0) {
                toast.info(
                  'Nada foi encontrado com os parâmetros informados!',
                  { timeout: 2000 }
                );
              }
            }
          })
          .catch(() => {
            this.searchSubmit = false;
          });
      }
    },

    async checkACL() {
      this.$store
        .dispatch('ACLItems', 8)
        .then(r => {
          if (r.indexOf('inventorie-search') !== -1) {
            this.viewPage = true;

            if (r.indexOf('inventorie-create') !== -1) {
              this.create = true;
            }
          } else {
            this.$router.push({ name: 'home' });
          }
        })
        .catch(() => {
          this.$router.push({ name: 'home' });
        });
    },

    stringType(value) {
      if (parseInt(value) === 1) return 'Entrada';
      return 'Saída';
    },

    stringShop(value) {
      if (parseInt(value) === 1) return 'E-commerce';
      return 'Loja';
    }
  },

  computed: {
    object() {
      return this.$store.state.inventory.items;
    },

    params() {
      return {
        page: this.object.current_page,
        name: this.formData.name,
        type: this.formData.type,
        shop_store: this.formData.shop_store
      };
    },

    selectEntranceExit() {
      return this.$store.state.var.selectEntranceExit;
    },

    selectShopStore() {
      return [
        { label: 'Loja', value: 1 },
        { label: 'BW E-commerce', value: 2 }
      ];
    }
  }
};
</script>
