import { createRouter, createWebHistory } from 'vue-router';
import routes from '@/router/routes';
import store from '@/store';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(to => {
  if (to.meta.auth && !store.state.auth.authenticate) {
    return router.push({ name: 'login' });
  }

  if (to.name === 'login' && store.state.auth.authenticate) {
    return router.push({ name: 'home' });
  }
});

export default router;
