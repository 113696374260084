import axios from 'axios';
import { URL_BASE } from '@/configs/constants';

const RESOURCE = 'client';
export default {
  loadCustomers(context, params) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL_BASE}${RESOURCE}`, { params })
        .then(response => {
          context.commit('CLIENT', response.data);
          return resolve(response.data);
        })
        .catch(errors => {
          return reject(errors);
        })
        .finally(() => context.commit('LOADER', false));
    });
  },

  storeClient(context, formData) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL_BASE}${RESOURCE}`, formData)
        .then(response => {
          return resolve(response.data);
        })
        .catch(errors => {
          return reject(errors);
        })
        .finally(() => context.commit('LOADER', false));
    });
  },

  loadClient(context, id) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL_BASE}${RESOURCE}/${id}`)
        .then(response => {
          return resolve(response.data);
        })
        .catch(errors => {
          return reject(errors);
        })
        .finally(() => context.commit('LOADER', false));
    });
  },

  updateClient(context, formData) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .put(`${URL_BASE}${RESOURCE}/${formData.id}`, formData)
        .then(response => {
          return resolve(response.data);
        })
        .catch(errors => {
          return reject(errors);
        })
        .finally(() => context.commit('LOADER', false));
    });
  },

  destroyClient(context, id) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .delete(`${URL_BASE}${RESOURCE}/${id}`)
        .then(response => {
          return resolve(response.data);
        })
        .catch(errors => {
          return reject(errors);
        })
        .finally(() => context.commit('LOADER', false));
    });
  },

  updateClientPhoto(context, formData) {
    let config = { headers: { 'Content-Type': 'multipart/form-data' } };
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL_BASE}client-photo`, formData, config)
        .then(response => {
          resolve(response);
        })
        .catch(errors => reject(errors));
    });
  },

  searchCNPJ(context, formData) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL_BASE}client-exist`, formData)
        .then(response => {
          return resolve(response.data);
        })
        .catch(errors => {
          return reject(errors);
        })
        .finally(() => context.commit('LOADER', false));
    });
  },

  searchCPF(context, formData) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL_BASE}client-exist`, formData)
        .then(response => {
          return resolve(response.data);
        })
        .catch(errors => {
          return reject(errors);
        })
        .finally(() => context.commit('LOADER', false));
    });
  },

  storeClientAdress(context, formData) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL_BASE}client-address/${formData.id}`, formData)
        .then(res => {
          return resolve(res.data);
        })
        .catch(err => {
          return reject(err);
        })
        .finally(() => context.commit('LOADER', false));
    });
  },

  loadClientList(context) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL_BASE}client-list`)
        .then(response => {
          context.commit('CLIENT', response.data);
          return resolve(response.data);
        })
        .catch(errors => {
          return reject(errors);
        })
        .finally(() => context.commit('LOADER', false));
    });
  }
};
